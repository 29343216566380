import { useCallback, useEffect } from "react";
import { NavigateOptions, useSearchParams } from "react-router-dom";
import useKeywordApi from "src/api/useKeywordApi";
import { GenericResponse } from "src/interfaces/api";
import { Keyword, TableOrderDirection } from "src/interfaces/store";
import { KeywordStore } from "../../store";

type SetString = (key: string) => void;
type SetNumber = (key: number) => void;
type SetOrder = (dir: TableOrderDirection, key: keyof Keyword) => void;

export default function useKeywordsStore(): {
  setPage: SetNumber;
  setPageSize: SetNumber;
  setOrderBy: SetOrder;
  setKeywords: SetString;
  updateMatchInDescription: (word: string, matchInDescription: boolean) => void;
  updateIsolated: (word: string, matchInDescription: boolean) => void;
  deleteKeyword: (word: string) => Promise<GenericResponse>;
  addNewKeyword: (params: {
    keyword: string;
    isolated: boolean;
    matchInDescription: boolean;
  }) => Promise<GenericResponse>;
} {
  const { listKeywords, updateKeyword, addKeyword, removeKeyword } =
    useKeywordApi();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page");
  const pageSize = searchParams.get("pageSize");
  const keywords = searchParams.get("keywords");
  const orderBy = searchParams.get("orderBy") as keyof Keyword | undefined;
  const orderDirection = searchParams.get("orderDirection") as
    | TableOrderDirection
    | undefined;

  const setPage = useCallback(
    (page: number, navigateOpts?: NavigateOptions) => {
      searchParams.set("page", page.toString());
      setSearchParams(searchParams, navigateOpts);
    },
    [searchParams, setSearchParams]
  );

  const setPageSize = useCallback(
    (pageSize: number) => {
      searchParams.set("pageSize", pageSize.toString());
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const setKeywords = useCallback(
    (keywords: string) => {
      if (keywords !== "") {
        searchParams.set("keywords", keywords);
      } else {
        searchParams.delete("keywords");
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const setOrderBy = useCallback(
    (dir: TableOrderDirection, key: keyof Keyword) => {
      searchParams.set("orderDirection", dir);
      searchParams.set("orderBy", key);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  useEffect(() => {
    // initializer
    if (!searchParams.has("page")) {
      setPage(1, { replace: true });
    }
  }, [searchParams, setPage, setSearchParams]);

  useEffect(() => {
    if (!page) {
      return;
    }
    KeywordStore.update((s) => {
      s.loading = true;
    });
    const controller = new AbortController();
    (async () => {
      try {
        const search = {
          page: parseInt(page, 10),
          ...(pageSize ? { pageSize } : {}),
          ...(orderBy ? { orderBy } : {}),
          ...(orderDirection ? { orderDirection } : {}),
          ...(keywords ? { keywords } : {}),
        };
        const result = await listKeywords(search, controller.signal);
        KeywordStore.update((s) => {
          s.list = result.list;
          s.page = result.page;
          s.totalAmount = result.totalAmount;
          s.pageSize = result.pageSize;
          s.orderBy = result.orderBy;
          s.orderDirection = result.orderDirection;
          s.loading = false;
        });
      } catch (err) {
        if ((err as DOMException).name !== "AbortError") {
          KeywordStore.update((s) => {
            s.error = err as Error;
            s.loading = false;
          });
        }
      }
    })();
    return () => controller.abort();
  }, [listKeywords, pageSize, page, orderBy, orderDirection, keywords]);

  const updateMatchInDescription = useCallback(
    async (word: string, matchInDescription: boolean) => {
      try {
        await updateKeyword({
          word,
          body: { matchInDescription },
        });
        KeywordStore.update((s) => {
          const auxList = [...s.list];
          const keyword = auxList.find((e) => e.word === word);
          if (!!keyword) {
            keyword.matchInDescription = matchInDescription;
          }
          s.list = auxList;
        });
      } catch (e) {
        console.error(e);
      }
    },
    [updateKeyword]
  );

  const deleteKeyword = useCallback(
    async (word: string) => {
      const resp = await removeKeyword({ word });
      if (resp.status === "success") {
        KeywordStore.update((s) => {
          s.list = s.list.filter((k) => k.word !== word);
        });
      }
      return resp;
    },
    [removeKeyword]
  );

  const addNewKeyword = useCallback(
    async ({
      keyword,
      isolated,
      matchInDescription,
    }: {
      keyword: string;
      isolated: boolean;
      matchInDescription: boolean;
    }) => {
      const resp = await addKeyword({
        word: keyword,
        isolated,
        matchInDescription,
      });
      if (resp.status === "success") {
        KeywordStore.update((s) => {
          s.list.unshift({
            word: keyword,
            isolated,
            matchInDescription,
            additional: 0,
          });
        });
      }
      return resp;
    },
    [addKeyword]
  );

  const updateIsolated = useCallback(
    async (word: string, isolated: boolean) => {
      try {
        await updateKeyword({
          word,
          body: { isolated },
        });
        KeywordStore.update((s) => {
          const auxList = [...s.list];
          const keyword = auxList.find((e) => e.word === word);
          if (!!keyword) {
            keyword.isolated = isolated;
          }
          s.list = auxList;
        });
      } catch (e) {
        console.error(e);
      }
    },
    [updateKeyword]
  );

  return {
    setPage,
    setPageSize,
    setOrderBy,
    setKeywords,
    updateMatchInDescription,
    updateIsolated,
    deleteKeyword,
    addNewKeyword,
  };
}
