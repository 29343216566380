import cls from "classnames";
import { FC } from "react";
import st from "./spinner.module.scss";

interface Props {
  show: boolean;
  className?: string;
}

const Spinner: FC<Props> = ({ show, className }) => {
  return (
    <div className={cls(className, st.ldsRing, { [st.show]: show })}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Spinner;
