import { useGoogleLogin } from "@react-oauth/google";
import { FC } from "react";
import useGoogleAuthApi from "src/api/useGoogleAuthApi";
import useUpdateSessionData from "src/hooks/useUpdateSessionData";
import { SessionStore } from "src/store";
import useGoogleLogout from "../../hooks/useGoogleLogout";
import st from "./login-button.module.scss";

const LoginButton: FC = () => {
  const logout = useGoogleLogout();
  const { getToken } = useGoogleAuthApi();
  const updateSession = useUpdateSessionData(SessionStore);

  const googleLogin = useGoogleLogin({
    onError: () => logout(),
    onSuccess: async ({ code }) => {
      try {
        const resp = await getToken({ code });
        updateSession({
          access_token: resp.tokens.access_token,
          expires_at: resp.tokens.expires_at,
          expires_in: resp.tokens.expires_in,
          id_token: resp.tokens.id_token,
          refresh_token: resp.tokens.refresh_token,
          scope: resp.tokens.scope,
          token_type: resp.tokens.token_type,
        });
      } catch (err) {
        logout();
      }
    },
    flow: "auth-code",
  });

  return (
    <button onClick={googleLogin} className={st.googleBtn}>
      <div className={st.googleIconWrapper}>
        <img
          alt="Google Auth"
          className={st.googleIcon}
          src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
        />
      </div>
      <p className={st.btnText}>
        <b>Sign in with google</b>
      </p>
    </button>
  );
};

export default LoginButton;
