import { useCallback } from "react";
import {
  GenericResponse,
  IListAccountRequest,
  IListLeadersRequest,
  IListPoiRequest,
} from "src/interfaces/api";
import {
  Account,
  AccountList,
  BiosAccountsList,
  LeaderList,
  Poi,
  PoiList,
} from "../interfaces/store";
import useApi from "./useApi";

interface IGetPoiByIdRequest {
  poiId: string;
}

interface IUpdateAccountActiveRequest {
  accountId: string;
  accountType: string;
  active: boolean;
}

interface IUpdateBioDiffCheckedRequest {
  accountId: string;
  accountType: string;
}

interface IUpdateAccountToScrapeRequest {
  accountId: string;
  accountType: string;
  toScrape: boolean;
}

interface IAddKeywordRequest {
  word: string;
  isolated: boolean;
  matchInDescription: boolean;
  toBlacklist?: boolean;
}

interface IDeleteKeywordRequest {
  poiId: string;
  word: string;
  isBlacklist?: boolean;
}

interface IAddNewPoiRequest {
  name: string;
  description: string;
}

export interface AddNewPoiResponse extends GenericResponse {
  poi: Poi;
}

interface IAddNewAccountRequest {
  poiId: string;
  accountUrl: string;
}

export interface AddNewAccountResponse extends GenericResponse {
  account: Account;
}

interface IDeletePoiRequest {
  poiId: string;
}

interface IDeletePoisRequest {
  poisIds: number[];
}

interface IDeleteAccountRequest {
  accountType: string;
  accountId: string;
}

type IGetPoiById = (
  params: IGetPoiByIdRequest,
  signal?: AbortSignal
) => Promise<Poi>;
type IListAllAccounts = (
  params: IListAccountRequest,
  signal?: AbortSignal
) => Promise<AccountList>;
type IListAllBiosAccounts = (signal?: AbortSignal) => Promise<BiosAccountsList>;
type IListPois = (
  params: IListPoiRequest,
  signal?: AbortSignal
) => Promise<PoiList>;
type IUpdateAccountActive = (
  params: IUpdateAccountActiveRequest,
  signal?: AbortSignal
) => Promise<GenericResponse>;
type IUpdateBioDiffChecked = (
  params: IUpdateBioDiffCheckedRequest,
  signal?: AbortSignal
) => Promise<GenericResponse>;
type IUpdateAccountToScrape = (
  params: IUpdateAccountToScrapeRequest,
  signal?: AbortSignal
) => Promise<GenericResponse>;
type IAddKeyword = (
  poiId: string,
  params: IAddKeywordRequest,
  signal?: AbortSignal
) => Promise<GenericResponse>;
type IDeleteKeyword = (
  params: IDeleteKeywordRequest,
  signal?: AbortSignal
) => Promise<GenericResponse>;
type IAddNewPoi = (
  params: IAddNewPoiRequest,
  signal?: AbortSignal
) => Promise<AddNewPoiResponse>;
type IAddPoiNewAccount = (
  params: IAddNewAccountRequest,
  signal?: AbortSignal
) => Promise<AddNewAccountResponse>;
type IDeletePoi = (
  params: IDeletePoiRequest,
  signal?: AbortSignal
) => Promise<GenericResponse>;
type IDeletePois = (
  params: IDeletePoisRequest,
  signal?: AbortSignal
) => Promise<GenericResponse>;
type IDeleteAccount = (
  params: IDeleteAccountRequest,
  signal?: AbortSignal
) => Promise<GenericResponse>;
type IListLeaders = (
  params: IListLeadersRequest,
  signal?: AbortSignal
) => Promise<LeaderList>;

export default function usePoiApi(): {
  listPois: IListPois;
  getPoiById: IGetPoiById;
  addNewPoi: IAddNewPoi;
  deletePoi: IDeletePoi;
  listAllAccounts: IListAllAccounts;
  listAllBiosAccounts: IListAllBiosAccounts;
  updateAccountActive: IUpdateAccountActive;
  updateBioDiffChecked: IUpdateBioDiffChecked;
  updateAccountToScrape: IUpdateAccountToScrape;
  addPoiNewAccount: IAddPoiNewAccount;
  deleteAccount: IDeleteAccount;
  addKeyword: IAddKeyword;
  deleteKeyword: IDeleteKeyword;
  deletePoisList: IDeletePois;
  listLeaders: IListLeaders;
} {
  const request = useApi();

  const listPois: IListPois = useCallback(
    (
      { page, filterBy, pageSize, orderBy, orderDirection, keywords, accounts },
      signal
    ) =>
      request({
        endpoint: "/pois",
        method: "GET",
        search: {
          page: page.toString(),
          ...(filterBy ? { filterBy } : {}),
          ...(pageSize ? { pageSize } : {}),
          ...(orderBy ? { orderBy } : {}),
          ...(orderDirection ? { orderDirection } : {}),
          ...(keywords ? { keywords } : {}),
          ...(accounts ? { accounts } : {}),
        },
        signal,
      }),
    [request]
  );

  const getPoiById: IGetPoiById = useCallback(
    ({ poiId }, signal) =>
      request({
        endpoint: `/pois/${poiId}`,
        method: "GET",
        signal,
      }),
    [request]
  );

  const addNewPoi: IAddNewPoi = useCallback(
    ({ name, description }, signal) =>
      request<AddNewPoiResponse>({
        endpoint: "/pois",
        method: "PUT",
        body: JSON.stringify({
          name,
          description,
        }),
        signal,
      }),
    [request]
  );

  const deletePoi: IDeletePoi = useCallback(
    ({ poiId }, signal) =>
      request({
        endpoint: `/pois/${poiId}`,
        method: "DELETE",
        signal,
      }),
    [request]
  );

  const deletePoisList: IDeletePois = useCallback(
    ({ poisIds }, signal) =>
      request({
        endpoint: "/pois",
        method: "DELETE",
        body: JSON.stringify({ poisIds }),
        signal,
      }),
    [request]
  );

  const listAllAccounts: IListAllAccounts = useCallback(
    ({ page, name, pageSize, orderBy, orderDirection }, signal) =>
      request({
        endpoint: "/pois/accounts",
        method: "GET",
        search: {
          page: page.toString(),
          ...(name ? { name } : {}),
          ...(pageSize ? { pageSize } : {}),
          ...(orderBy ? { orderBy } : {}),
          ...(orderDirection ? { orderDirection } : {}),
        },
        signal,
      }),
    [request]
  );

  const listAllBiosAccounts: IListAllBiosAccounts = useCallback(
    (signal) =>
      request({
        endpoint: "/pois/accounts/descriptions/new",
        method: "GET",
        signal,
      }),
    [request]
  );

  const updateAccountActive: IUpdateAccountActive = useCallback(
    ({ accountId, accountType, active }, signal) =>
      request({
        endpoint: `/pois/accounts/${accountType}/${accountId}`,
        method: "PATCH",
        body: JSON.stringify({ active }),
        signal,
      }),
    [request]
  );

  const updateBioDiffChecked: IUpdateBioDiffChecked = useCallback(
    ({ accountId, accountType }, signal) =>
      request({
        endpoint: `/pois/accounts/${accountType}/${accountId}/checked`,
        method: "PATCH",
        signal,
      }),
    [request]
  );

  const updateAccountToScrape: IUpdateAccountToScrape = useCallback(
    ({ accountId, accountType, toScrape }, signal) =>
      request({
        endpoint: `/pois/accounts/${accountType}/${accountId}`,
        method: "PATCH",
        body: JSON.stringify({ toScrape }),
        signal,
      }),
    [request]
  );

  const addPoiNewAccount: IAddPoiNewAccount = useCallback(
    ({ accountUrl, poiId }, signal) =>
      request<AddNewAccountResponse>({
        endpoint: `/pois/${poiId}/accounts`,
        method: "PUT",
        body: JSON.stringify({
          url: accountUrl,
        }),
        signal,
      }),
    [request]
  );

  const deleteAccount: IDeleteAccount = useCallback(
    ({ accountType, accountId }, signal) =>
      request({
        endpoint: `/pois/accounts/${accountType}/${accountId}`,
        method: "DELETE",
        signal,
      }),
    [request]
  );

  const addKeyword: IAddKeyword = useCallback(
    (poiId, { word, isolated, matchInDescription, toBlacklist }, signal) =>
      request({
        endpoint: `/pois/${poiId}/keywords`,
        method: "PUT",
        body: JSON.stringify({
          word,
          isolated,
          matchInDescription,
          ...(toBlacklist ? { toBlacklist: true } : {}),
        }),
        signal,
      }),
    [request]
  );

  const deleteKeyword: IDeleteKeyword = useCallback(
    ({ poiId, word, isBlacklist }, signal) =>
      request({
        endpoint: `/pois/${poiId}/keywords/${word}`,
        method: "DELETE",
        body: JSON.stringify({
          ...(isBlacklist ? { isBlacklist: true } : {}),
        }),
        signal,
      }),
    [request]
  );

  const listLeaders: IListLeaders = useCallback(
    (
      { page, pageSize, orderBy, orderDirection, interval, intervalType },
      signal
    ) =>
      request({
        endpoint: "/pois/leaderboard",
        method: "GET",
        search: {
          page: page.toString(),
          ...(pageSize ? { pageSize } : {}),
          ...(orderBy ? { orderBy } : {}),
          ...(orderDirection ? { orderDirection } : {}),
          ...(interval ? { interval } : {}),
          ...(intervalType ? { intervalType } : {}),
        },
        signal,
      }),
    [request]
  );

  return {
    listPois,
    getPoiById,
    addNewPoi,
    deletePoi,
    listAllAccounts,
    listAllBiosAccounts,
    updateAccountActive,
    updateBioDiffChecked,
    updateAccountToScrape,
    addPoiNewAccount,
    deleteAccount,
    addKeyword,
    deleteKeyword,
    deletePoisList,
    listLeaders,
  };
}
