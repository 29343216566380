import { useCallback, useEffect, useState } from "react";

export default function useTheme(): ["dark" | "light", () => void] {
  const [theme, setTheme] = useState<"dark" | "light">(
    (sessionStorage.getItem("theme") as "dark" | "light") || "light"
  );

  useEffect(() => {
    setTheme(
      document.querySelector("body")?.classList.contains("dark")
        ? "dark"
        : "light"
    );
    const classWatcher = new ClassWatcher(
      document.querySelector("body") as HTMLBodyElement,
      "dark",
      () => {
        sessionStorage.setItem("theme", "dark");
        setTheme("dark");
      },
      () => {
        sessionStorage.setItem("theme", "light");
        setTheme("light");
      }
    );

    return () => classWatcher.disconnect();
  }, []);

  const toggleTheme = useCallback(
    () => document.querySelector("body")?.classList.toggle("dark"),
    []
  );

  return [theme, toggleTheme];
}

class ClassWatcher {
  targetNode: HTMLBodyElement;
  classToWatch: string;
  classAddedCallback: () => void;
  classRemovedCallback: () => void;
  lastClassState: boolean;
  observer?: MutationObserver;

  constructor(
    targetNode: HTMLBodyElement,
    classToWatch: string,
    classAddedCallback: () => void,
    classRemovedCallback: () => void
  ) {
    this.targetNode = targetNode;
    this.classToWatch = classToWatch;
    this.classAddedCallback = classAddedCallback;
    this.classRemovedCallback = classRemovedCallback;
    this.lastClassState = targetNode.classList.contains(this.classToWatch);

    this.init();
  }

  init() {
    this.observer = new MutationObserver(this.mutationCallback);
    this.observe();
  }

  observe() {
    this.observer?.observe(this.targetNode, { attributes: true });
  }

  disconnect() {
    this.observer?.disconnect();
  }

  mutationCallback = (mutationsList: MutationRecord[]) => {
    for (let mutation of mutationsList) {
      if (
        mutation.type === "attributes" &&
        mutation.attributeName === "class"
      ) {
        let currentClassState = (
          mutation.target as HTMLElement
        ).classList.contains(this.classToWatch);
        if (this.lastClassState !== currentClassState) {
          this.lastClassState = currentClassState;
          if (currentClassState) {
            this.classAddedCallback();
          } else {
            this.classRemovedCallback();
          }
        }
      }
    }
  };
}
