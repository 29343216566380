import cls from "classnames";
import { FC } from "react";
import {
  Button,
  ButtonGroup,
  Container,
  Dropdown,
  Table,
} from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import Spinner from "src/components/Spinner";
import TablePager from "src/components/TablePager";
import useLeaderStore from "src/hooks/endpoint/useLeaderStore";
import { LeaderEntry, TableOrderDirection } from "src/interfaces/store";
import { LeaderStore } from "src/store";
import { ReactComponent as DataNotFoundImage } from "../Matches/data-not-found.svg";
import matchesSt from "../Matches/matches.module.scss";
import RatioChart from "./RatioChart";

const LeaderBoard: FC = () => {
  const [searchParams] = useSearchParams();
  const { setPage, setPageSize, setOrderBy, setInterval } = useLeaderStore();
  const loading = LeaderStore.useState((s) => s.loading);
  const list = LeaderStore.useState((s) => s.list);
  const page = LeaderStore.useState((s) => s.page);
  const total = LeaderStore.useState((s) => s.totalAmount);
  const orderBy = LeaderStore.useState((s) => s.orderBy);
  const orderDirection = LeaderStore.useState((s) => s.orderDirection);
  const interval = LeaderStore.useState((s) => s.interval);
  const intervalType = LeaderStore.useState((s) => s.intervalType);
  const pageSize = searchParams.get("pageSize");
  const navigate = useNavigate();

  const updateOrder = (
    newOrder: keyof LeaderEntry,
    dir?: TableOrderDirection
  ) => {
    const direction = !!dir
      ? dir
      : orderBy === newOrder
      ? orderDirection === "ASC"
        ? "DESC"
        : "ASC"
      : "DESC";
    setOrderBy(direction, newOrder);
  };

  const ArrowButton = (
    order: keyof LeaderEntry,
    label: string,
    forceOrder?: TableOrderDirection
  ) => (
    <Button
      variant="secondary"
      active={orderBy === order}
      onClick={() => updateOrder(order, forceOrder)}
    >
      {label}
      {!forceOrder && orderBy === order && (
        <span>{orderDirection === "ASC" ? " ↑" : " ↓"}</span>
      )}
    </Button>
  );

  const pager = (showActions?: boolean) => (
    <TablePager
      total={total as number}
      page={page as number}
      pageSize={parseInt(pageSize || "20", 10)}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
      actionButtons={
        showActions ? (
          <>
            <ButtonGroup aria-label="Order">
              {ArrowButton("ratio", "Ratio")}
              {ArrowButton("TFEB", "TFEB")}
              {ArrowButton("invested", "Invested")}
              {ArrowButton("interested", "Interested")}
              {ArrowButton("blacklisted", "Blacklisted")}
            </ButtonGroup>
            <Dropdown
              onSelect={(ev) => {
                const int = parseInt(ev?.split(":")[0] || "1", 10);
                const typ = ev?.split(":")[1];
                setInterval(
                  int,
                  typ === "YEAR" ? "YEAR" : typ === "MONTH" ? "MONTH" : "WEEK"
                );
              }}
            >
              <Dropdown.Toggle variant="secondary" id="dropdown-interval">
                {interval}{" "}
                {intervalType.charAt(0).toUpperCase() +
                  intervalType.slice(1).toLowerCase()}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item eventKey={"1:WEEK"}>1 Week</Dropdown.Item>
                <Dropdown.Item eventKey={"1:MONTH"}>1 Month</Dropdown.Item>
                <Dropdown.Item eventKey={"2:MONTH"}>2 Month</Dropdown.Item>
                <Dropdown.Item eventKey={"3:MONTH"}>3 Month</Dropdown.Item>
                <Dropdown.Item eventKey={"6:MONTH"}>6 Month</Dropdown.Item>
                <Dropdown.Item eventKey={"1:YEAR"}>1 Year</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        ) : undefined
      }
    />
  );

  const handleOnRowClick = (poiId: number) => {
    navigate(`/scraper/dashboard/pois/${poiId}`);
  };

  return (
    <Container>
      <h1>Leader Board</h1>
      {pager(true)}
      <div className={matchesSt.tableContainer}>
        <Spinner className={matchesSt.spinner} show={loading} />
        <Table
          responsive="lg"
          className={cls(matchesSt.table, { [matchesSt.loading]: loading })}
        >
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Type</th>
              <th>Ratios</th>
            </tr>
          </thead>
          <tbody>
            {!loading && page > 0 && (!list || list.length === 0) ? (
              <tr>
                <td className={matchesSt.emptyState} colSpan={6}>
                  <DataNotFoundImage className={matchesSt.noDataImage} />
                  <br />
                  There is no data to display.
                </td>
              </tr>
            ) : (
              !!list &&
              list.map((entry) => {
                return (
                  <tr
                    key={entry.poiId}
                    className={matchesSt.rowEntry}
                    onClick={() => handleOnRowClick(entry.poiId)}
                  >
                    <td>{entry.poiId}</td>
                    <td>{entry.name}</td>
                    <td>{entry.description}</td>
                    <td>
                      <RatioChart entry={entry} />
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
      </div>
      {pager()}
    </Container>
  );
};

export default LeaderBoard;
