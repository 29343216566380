import cls from "classnames";
import { FC, useEffect, useState } from "react";
import { Badge, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useMatchApi from "src/api/useMatchApi";
import AccountsTable from "src/components/AccountsTable";
import ScoreButton from "src/components/ScoreButton";
import Spinner from "src/components/Spinner";
import useTheme from "src/hooks/useTheme";
import { Match as IMatch, MatchScore } from "src/interfaces/store";
import st from "./match.module.scss";

const Match: FC = () => {
  const { matchId } = useParams();
  const { getMatchById, updateMatch } = useMatchApi();
  const [match, setMatch] = useState<IMatch>();
  const [, setError] = useState<Error>();
  const [loading, setLoading] = useState(true);
  const [theme] = useTheme();

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      setLoading(true);
      try {
        const mt = await getMatchById(
          { matchId: matchId || "" },
          controller.signal
        );
        setMatch(mt);
        setLoading(false);
      } catch (err) {
        if ((err as DOMException).name !== "AbortError") {
          setLoading(false);
          console.error(err);
          setError(err as Error);
        }
      }
    })();
    return () => controller.abort();
  }, [getMatchById, matchId]);

  const handleOnScoreChange = async (match: IMatch, score: MatchScore) => {
    const resp = await updateMatch({
      body: { matchIds: [parseInt(matchId || "", 10)], values: { score } },
    });
    setMatch((prev) => (prev ? { ...prev, score } : undefined));
    return resp;
  };

  const handleOnReadChange = async (read?: boolean) => {
    if (matchId && match) {
      try {
        await updateMatch({
          body: {
            matchIds: [parseInt(matchId, 10)],
            values: {
              read: typeof read === "undefined" ? !match.read : read,
            },
          },
        });
        setMatch((prev) =>
          prev
            ? { ...prev, read: typeof read === "undefined" ? !prev.read : read }
            : undefined
        );
      } catch (e) {}
    }
  };

  return (
    <Container>
      <div className={cls(st.hero, { [st.isLoading]: loading })}>
        <div>
          <h1>Match {matchId}</h1>
          {!!match?.name && (
            <h2>
              {match.name}
              {!!match.position ? ` as ${match.position}` : ""}
              {!!match.company ? ` at ${match.company}` : ""}
            </h2>
          )}
          <a
            onClick={() => handleOnReadChange(true)}
            href={match?.url}
            target="_blank"
            rel="noreferrer"
          >
            <h2 className={st.truncate}>
              {match?.url || (loading ? "Loading..." : "-")}
            </h2>
          </a>
        </div>
        <div className={st.scoreChart}>
          <ScoreButton
            changeScore={handleOnScoreChange}
            match={match}
            noDataLabel={loading ? "...Loading" : "-"}
          />
        </div>
      </div>
      <div className={cls(st.infos, { [st.isLoading]: loading })}>
        <div>
          <h5>Initial Match Date</h5>
          <p>
            {!match
              ? loading
                ? "Loading..."
                : "-"
              : new Date(match.initialMatchDate).toLocaleString()}
          </p>
        </div>
        <div>
          <h5>Last Match Date</h5>
          <p>
            {!match
              ? loading
                ? "Loading..."
                : "-"
              : new Date(match.lastMatchDate).toLocaleString()}
          </p>
        </div>
        <div>
          <h5>Read</h5>
          <button
            disabled={!match || loading}
            className={cls(st.noBtn, { [st.spinning]: loading })}
            onClick={() => handleOnReadChange()}
          >
            {match?.read ? (
              <i className={cls("bi bi-eye-fill", st.icon, st.success)}></i>
            ) : loading ? (
              <i className={cls("bi bi-arrow-repeat", st.icon, st.success)}></i>
            ) : !!match ? (
              <i
                className={cls("bi bi-eye-slash-fill", st.icon, st.danger)}
              ></i>
            ) : (
              "-"
            )}
          </button>
        </div>
      </div>
      <Spinner className={st.spinner} show={loading} />
      {!match && !loading && (
        <div className={st.matchNotFound}>
          <h2>Match not found!</h2>
        </div>
      )}
      {!!match && (
        <div className={cls(st.additionalContent, { [st.isLoading]: loading })}>
          <h3>Accounts</h3>
          <AccountsTable
            onUpdate={(newAccount, update) => {
              setMatch((prev) =>
                !!prev
                  ? {
                      ...prev,
                      accounts: prev.accounts.map((acc) => {
                        if (
                          acc.accountId === newAccount.accountId &&
                          acc.type === newAccount.type
                        ) {
                          return { ...acc, ...update };
                        }
                        return acc;
                      }),
                    }
                  : undefined
              );
            }}
            onDelete={(account) => {
              setMatch((prev) =>
                !!prev
                  ? {
                      ...prev,
                      accounts: prev.accounts.filter(
                        (acc) =>
                          acc.type !== account.type &&
                          acc.accountId !== account.accountId
                      ),
                    }
                  : undefined
              );
            }}
            accounts={match.accounts}
          />
          {match.keywords.length > 0 && (
            <>
              <h3>Keywords</h3>
              <div className={st.keywordsList}>
                {match.keywords.map((keyword) => (
                  <Badge
                    pill
                    bg={theme === "dark" ? "light" : "dark"}
                    key={keyword.word}
                  >
                    {keyword.word}
                  </Badge>
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </Container>
  );
};

export default Match;
