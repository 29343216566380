import App from "../App";
import AccountsBios from "./AccountsBios";
import Dashboard from "./Dashboard";
import LeaderBoard from "./LeaderBoard";
import Match from "./Match";
import Matches from "./Matches";
import Poi from "./Poi";
import Grants from "./Grants";
import Unauthorized from "./Unauthorized";
import { Navigate, RouteObject } from "react-router-dom";

const router: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    errorElement: <App error />,
    children: [
      {
        path: "/",
        element: <Navigate replace to="/scraper" />,
      },
      {
        path: "/unauthorized",
        element: <Unauthorized />,
      },
      {
        path: "/scraper",
        element: <Matches />,
      },
      {
        path: "/scraper/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/scraper/dashboard/:page",
        element: <Dashboard />,
      },
      {
        path: "/scraper/dashboard/pois/:poiId",
        element: <Poi />,
      },
      {
        path: "/scraper/matches/:matchId",
        element: <Match />,
      },
      {
        path: "/scraper/leaderboard",
        element: <LeaderBoard />,
      },
      {
        path: "/scraper/accounts-bios",
        element: <AccountsBios />,
      },
      {
        path: "/scraper/grants",
        element: <Grants />,
      },
    ],
  },
];

export default router;
