import { useCallback, useEffect } from "react";
import { NavigateOptions, useSearchParams } from "react-router-dom";
import usePoiApi from "src/api/usePoiApi";
import { Poi, TableOrderDirection } from "src/interfaces/store";
import { PoiStore } from "src/store";

type SetString = (key: string) => void;
type SetNumber = (key: number) => void;
type SetOrder = (dir: TableOrderDirection, key: keyof Poi) => void;

type SetFilterBy = (key: keyof Poi, value: string) => void;

export default function usePoisStore(): {
  setPage: SetNumber;
  setPageSize: SetNumber;
  setOrderBy: SetOrder;
  setKeywords: SetString;
  setAccounts: SetString;
  updateFilterBy: SetFilterBy;
} {
  const { listPois } = usePoiApi();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page");
  const pageSize = searchParams.get("pageSize");
  const keywords = searchParams.get("keywords");
  const accounts = searchParams.get("accounts");
  const filterBy = searchParams.get("filterBy");
  const orderBy = searchParams.get("orderBy") as keyof Poi | undefined;
  const orderDirection = searchParams.get("orderDirection") as
    | TableOrderDirection
    | undefined;

  const setPage = useCallback(
    (page: number, navigateOpts?: NavigateOptions) => {
      searchParams.set("page", page.toString());
      setSearchParams(searchParams, navigateOpts);
    },
    [searchParams, setSearchParams]
  );

  const updateFilterBy = useCallback(
    (key: string, value: string) => {
      const oldFilterBy = JSON.parse(searchParams.get("filterBy") || "{}");
      let filterBy: Record<string, string> = { ...oldFilterBy };
      if (value !== "") {
        filterBy[key] = value;
      } else {
        let { [key]: _, ...newFilterBy } = filterBy;
        filterBy = newFilterBy;
      }
      if (Object.keys(filterBy).length === 0) {
        searchParams.delete("filterBy");
      } else {
        searchParams.set("filterBy", JSON.stringify(filterBy));
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const setPageSize = useCallback(
    (pageSize: number) => {
      searchParams.set("pageSize", pageSize.toString());
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const setKeywords = useCallback(
    (keywords: string) => {
      if (keywords !== "") {
        searchParams.set("keywords", keywords);
      } else {
        searchParams.delete("keywords");
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const setAccounts = useCallback(
    (accounts: string) => {
      if (accounts !== "") {
        searchParams.set("accounts", accounts);
      } else {
        searchParams.delete("accounts");
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const setOrderBy = useCallback(
    (dir: TableOrderDirection, key: keyof Poi) => {
      searchParams.set("orderDirection", dir);
      searchParams.set("orderBy", key);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  useEffect(() => {
    // initializer
    if (!searchParams.has("page")) {
      setPage(1, { replace: true });
    }
  }, [searchParams, setPage, setSearchParams]);

  useEffect(() => {
    if (!page) {
      return;
    }
    PoiStore.update((s) => {
      s.loading = true;
    });
    const controller = new AbortController();
    (async () => {
      const fb = JSON.parse(filterBy || "{}");
      try {
        const search = {
          page: parseInt(page, 10),
          ...(pageSize ? { pageSize } : {}),
          ...(orderBy ? { orderBy } : {}),
          ...(orderDirection ? { orderDirection } : {}),
          ...(keywords ? { keywords } : {}),
          ...(accounts ? { accounts } : {}),
          ...(filterBy ? { filterBy } : {}),
        };
        const result = await listPois(search, controller.signal);
        PoiStore.update((s) => {
          s.list = result.list;
          s.page = result.page;
          s.totalAmount = result.totalAmount;
          s.pageSize = result.pageSize;
          s.orderBy = result.orderBy;
          s.orderDirection = result.orderDirection;
          s.filterBy = Object.keys(fb).length > 0 ? fb : null;
          s.types = result.types;
          s.loading = false;
        });
      } catch (err) {
        if ((err as DOMException).name !== "AbortError") {
          PoiStore.update((s) => {
            s.error = err as Error;
            s.loading = false;
          });
        }
      }
    })();
    return () => controller.abort();
  }, [
    accounts,
    keywords,
    listPois,
    filterBy,
    orderBy,
    orderDirection,
    page,
    pageSize,
  ]);

  return {
    setPage,
    setPageSize,
    setOrderBy,
    setKeywords,
    setAccounts,
    updateFilterBy,
  };
}
