import { useCallback } from "react";
import { IGrantsListRequest } from "src/interfaces/api";
import { GrantsResponse, IGitcoinSortOptions } from "src/interfaces/gitCoin";
import useApi from "./useApi";

type IListGrants = (
  params: IGrantsListRequest<IGitcoinSortOptions>,
  signal?: AbortSignal
) => Promise<GrantsResponse>;

export default function useGrantsApi(): {
  listGrants: IListGrants;
} {
  const request = useApi();

  const listGrants: IListGrants = useCallback(
    ({ page, pageSize = 20, orderBy }, signal) =>
      request({
        endpoint: "/grants",
        method: "GET",
        search: {
          page: page.toString(),
          pageSize: pageSize.toString(),
          ...(orderBy ? { orderBy } : {}),
        },
        signal,
      }),
    [request]
  );

  return { listGrants };
}
