import { FC, ReactElement, useEffect } from "react";
import { Location, Navigate, useLocation } from "react-router-dom";
import useRefreshToken from "src/hooks/useRefreshToken";
import { SessionStore } from "../../store";
interface Props {
  children: ReactElement;
  error?: boolean;
}

const AuthGate: FC<Props> = ({ children, error }) => {
  const location = useLocation();
  const [credentials, path] = SessionStore.useState<
    [string, Location | undefined, string, string]
  >((s) => [s.id_token, s.path, s.expires_at, s.refresh_token]);

  useRefreshToken();

  useEffect(() => {
    if (!error && location.pathname !== "/unauthorized" && !credentials) {
      SessionStore.update((s) => {
        s.path = location;
      });
    }
    if (!error && location.pathname === "/unauthorized" && credentials) {
      SessionStore.update((s) => {
        s.path = undefined;
      });
    }
  }, [credentials, location, error]);

  if (!error && location.pathname !== "/unauthorized" && !credentials) {
    return <Navigate to="/unauthorized" replace />;
  }
  if (!error && location.pathname === "/unauthorized" && credentials) {
    return <Navigate to={path || "/"} replace />;
  }
  return <>{children}</>;
};

export default AuthGate;
