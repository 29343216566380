import { GoogleOAuthProvider } from "@react-oauth/google";
import { FC } from "react";
import { Container } from "react-bootstrap";
import { QueryClient, QueryClientProvider } from "react-query";
import { Outlet } from "react-router-dom";
import st from "./App.module.scss";
import AuthGate from "./components/AuthGate";
import Header from "./components/Header";
import ErrorPage from "./pages/Error";

const queryClient = new QueryClient();

const GOOGLE_AUTH_PROVIDER_ID = process.env
  .REACT_APP_GOOGLE_AUTH_PROVIDER_ID as string;

interface Props {
  error?: boolean;
}

const App: FC<Props> = ({ error }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <GoogleOAuthProvider clientId={GOOGLE_AUTH_PROVIDER_ID}>
        <AuthGate error={error}>
          <div className={st.App}>
            <Header />
            <Container>{error ? <ErrorPage /> : <Outlet />}</Container>
            <footer className={st.footer}></footer>
          </div>
        </AuthGate>
      </GoogleOAuthProvider>
    </QueryClientProvider>
  );
};

export default App;
