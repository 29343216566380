import { FC, ReactNode } from "react";
import { Button, Dropdown } from "react-bootstrap";
import st from "./table-pager.module.scss";

interface Props {
  total: number;
  page: number;
  pageSize: number;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newSize: number) => void;
  actionButtons?: ReactNode;
}

const TablePager: FC<Props> = ({
  total,
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
  actionButtons,
}) => {
  const handleOnSizeChange = (ev: any) => {
    onPageSizeChange(parseInt(ev.target.innerText, 10));
  };

  const totalPages = Math.ceil(total / pageSize);

  return (
    <div className={st.tablePager}>
      <div className={st.leftActions}>
        <Dropdown>
          <Dropdown.Toggle>{pageSize}</Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item as="button" onClick={handleOnSizeChange}>
              10
            </Dropdown.Item>
            <Dropdown.Item as="button" onClick={handleOnSizeChange}>
              20
            </Dropdown.Item>
            <Dropdown.Item as="button" onClick={handleOnSizeChange}>
              40
            </Dropdown.Item>
            <Dropdown.Item as="button" onClick={handleOnSizeChange}>
              80
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {!!actionButtons ? actionButtons : ""}
      </div>
      <div className={st.Pager}>
        <Button disabled={page <= 1} onClick={() => onPageChange(1)}>
          <i className="bi bi-chevron-double-left"></i>
        </Button>
        <Button
          disabled={page <= 1 || page > totalPages + 1}
          onClick={() => onPageChange(page - 1)}
        >
          <i className="bi bi-chevron-left"></i>
        </Button>
        {page} of {totalPages}
        <Button
          disabled={page >= totalPages}
          onClick={() => onPageChange(page + 1)}
        >
          <i className="bi bi-chevron-right"></i>
        </Button>
        <Button
          disabled={page >= totalPages}
          onClick={() => onPageChange(totalPages)}
        >
          <i className="bi bi-chevron-double-right"></i>
        </Button>
      </div>
    </div>
  );
};

export default TablePager;
