import { FC } from "react";
import { ToggleButton } from "react-bootstrap";
import useTheme from "../../hooks/useTheme";

interface Props {
  className?: string;
}

const ThemeButton: FC<Props> = ({ className }) => {
  const [theme, toggleTheme] = useTheme();

  return (
    <ToggleButton
      id="toggle-check"
      type="checkbox"
      variant="outline-primary"
      checked={theme === "dark"}
      value="1"
      onChange={toggleTheme}
      className={className}
    >
      {theme === "dark" ? (
        <i className="bi bi-sun-fill"></i>
      ) : (
        <i className="bi bi-moon-stars-fill"></i>
      )}
    </ToggleButton>
  );
};

export default ThemeButton;
