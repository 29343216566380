import cls from "classnames";
import { FC, useEffect, useRef, useState } from "react";
import { Button, Dropdown, Form, Table } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import useKeywordApi from "src/api/useKeywordApi";
import AsyncModal from "src/components/Modal/AsyncModal";
import ModalAddKeyword from "src/components/Modal/ModalAddKeyword";
import Spinner from "src/components/Spinner";
import TableOrderButton from "src/components/TableOrderButton";
import TablePager from "src/components/TablePager";
import useKeywordsStore from "src/hooks/endpoint/useKeywordsStore";
import {
  GenericKeyword,
  Keyword,
  TableOrderDirection,
} from "src/interfaces/store";
import { KeywordStore } from "src/store";
import { ReactComponent as DataNotFoundImage } from "../Matches/data-not-found.svg";
import matchSt from "../Matches/matches.module.scss";
import st from "./dashboard.module.scss";

interface Props {
  className?: string;
  genericList?: boolean;
}

const KeywordsListTab: FC<Props> = ({ className, genericList }) => {
  const [search, setSearch] = useState("");
  const [searchParams] = useSearchParams();
  const {
    setPage,
    setPageSize,
    setOrderBy,
    setKeywords,
    updateMatchInDescription,
    updateIsolated,
    addNewKeyword,
    deleteKeyword,
  } = useKeywordsStore();
  const { removeKeywordsList } = useKeywordApi();
  const loading = KeywordStore.useState((s) => s.loading);
  const list = KeywordStore.useState((s) => s.list);
  const page = KeywordStore.useState((s) => s.page);
  const total = KeywordStore.useState((s) => s.totalAmount);
  const pageSize = searchParams.get("pageSize");
  const orderBy = KeywordStore.useState((s) => s.orderBy);
  const orderDirection = KeywordStore.useState((s) => s.orderDirection);
  const [showAddKey, setShowAddKey] = useState(false);
  const [showDeleteWord, setShowDeleteWord] = useState<GenericKeyword>();
  const [showBulkDeleteWords, setShowBulkDeleteWords] = useState<boolean>();
  const [bulkSelected, setBulkSelected] = useState<string[]>([]);
  const shiftKey = useRef(false);

  const pager = (showActions?: boolean) => (
    <TablePager
      total={total as number}
      page={page as number}
      pageSize={parseInt(pageSize || "20", 10)}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
      actionButtons={
        showActions ? (
          <>
            <Form.Control
              value={search}
              onChange={(ev) => setSearch(ev.target.value)}
              placeholder="search"
            />
            <Button onClick={() => setShowAddKey(true)}>
              <i className={"bi bi-plus-lg"}></i>
            </Button>
            <Form.Group>
              <Dropdown onSelect={bulkActionSelected}>
                <Dropdown.Toggle
                  disabled={bulkSelected.length === 0}
                  variant="primary"
                  id="dropdown-bulk"
                >
                  Bulk Actions
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="delete">Delete</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>
          </>
        ) : (
          ""
        )
      }
    />
  );

  const handleOnSaveNewKeyword = async (keywords: Keyword[]) => {
    const word = keywords[0].word;
    const isolated = keywords[0].isolated;
    const matchInDescription = keywords[0].matchInDescription;
    return addNewKeyword({ keyword: word, isolated, matchInDescription });
  };

  const handleOnUpdateOrder = (
    dir: TableOrderDirection,
    key: keyof Keyword
  ) => {
    setOrderBy(dir, key);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setKeywords(search);
    }, 500);
    return () => clearTimeout(timeout);
  }, [search, setKeywords]);

  const handleOnDeleteKeywordsList = async () => {
    const resp = await removeKeywordsList({ keywords: bulkSelected });
    if (resp.status === "success") {
      KeywordStore.update((s) => {
        s.list = s.list.filter((k) =>
          bulkSelected.every((word) => k.word !== word)
        );
      });
    }
    return resp;
  };

  const handleOnSelectAll = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (ev.target.checked) {
      setBulkSelected(list.map((word) => word.word));
    } else {
      setBulkSelected([]);
    }
  };

  const handleOnSelectedRow = (
    ev: React.ChangeEvent<HTMLInputElement>,
    word: string
  ) => {
    if (ev.target.checked) {
      if (shiftKey.current) {
        setBulkSelected((prev) => {
          const allEls = list.map((el) => el.word);
          const firstIndex = allEls.indexOf(prev.slice(-1)[0]);
          const secIndex = allEls.indexOf(word);
          const finalArr = allEls.slice(
            firstIndex < secIndex ? firstIndex : secIndex,
            firstIndex > secIndex ? firstIndex : secIndex + 1
          );
          return prev.concat(
            finalArr.filter((item) => prev.indexOf(item) === -1)
          );
        });
      } else {
        setBulkSelected((prev) => [...prev, word]);
      }
    } else {
      setBulkSelected((prev) => prev.filter((el) => el !== word));
    }
  };

  const bulkActionSelected = (ev: string | null) => {
    if (ev === "delete") {
      setShowBulkDeleteWords(true);
    }
  };

  return (
    <div className={className}>
      {pager(true)}
      <div className={matchSt.tableContainer}>
        <Spinner className={matchSt.spinner} show={loading} />
        <Table className={cls(matchSt.table, { [matchSt.loading]: loading })}>
          <thead>
            <tr>
              <th>
                <TableOrderButton
                  onClick={handleOnUpdateOrder}
                  orderDirection={orderDirection}
                  orderKey="word"
                  order={orderBy}
                  onCheck={handleOnSelectAll}
                  checked={(list || []).length === bulkSelected.length}
                >
                  ID
                </TableOrderButton>
              </th>
              <th>
                <TableOrderButton
                  onClick={handleOnUpdateOrder}
                  orderDirection={orderDirection}
                  orderKey="matchInDescription"
                  order={orderBy}
                >
                  Match in description
                </TableOrderButton>
              </th>
              <th>
                <TableOrderButton
                  onClick={handleOnUpdateOrder}
                  orderDirection={orderDirection}
                  orderKey="isolated"
                  order={orderBy}
                >
                  Is isolated
                </TableOrderButton>
              </th>
              {genericList && (
                <th>
                  <TableOrderButton
                    onClick={handleOnUpdateOrder}
                    orderDirection={orderDirection}
                    orderKey="additional"
                    order={orderBy}
                  >
                    Additional
                  </TableOrderButton>
                </th>
              )}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {!loading && page > 0 && (!list || list.length === 0) ? (
              <tr>
                <td className={matchSt.emptyState} colSpan={6}>
                  <DataNotFoundImage className={matchSt.noDataImage} />
                  <br />
                  There is no data to display.
                </td>
              </tr>
            ) : (
              !!list &&
              list.map((keyword) => {
                return (
                  <tr className={matchSt.rowEntry} key={keyword.word}>
                    <td>
                      <div className={matchSt.idTable}>
                        <input
                          checked={bulkSelected.some(
                            (el) => el === keyword.word
                          )}
                          onClick={(ev) => {
                            ev.stopPropagation();
                            shiftKey.current = ev.shiftKey;
                          }}
                          type="checkbox"
                          onChange={(ev) =>
                            handleOnSelectedRow(ev, keyword.word)
                          }
                        />
                        {keyword.word}
                      </div>
                    </td>
                    <td>
                      <button
                        className={st.noBtn}
                        onClick={(ev) => {
                          ev.stopPropagation();
                          updateMatchInDescription(
                            keyword.word,
                            !keyword.matchInDescription
                          );
                        }}
                      >
                        {keyword.matchInDescription ? (
                          <i
                            className={cls(
                              "bi bi-toggle-on",
                              matchSt.icon,
                              matchSt.success
                            )}
                          ></i>
                        ) : (
                          <i
                            className={cls(
                              "bi bi-toggle-off",
                              matchSt.icon,
                              matchSt.danger
                            )}
                          ></i>
                        )}
                      </button>
                    </td>
                    <td>
                      <button
                        className={st.noBtn}
                        onClick={(ev) => {
                          ev.stopPropagation();
                          updateIsolated(keyword.word, !keyword.isolated);
                        }}
                      >
                        {keyword.isolated ? (
                          <i
                            className={cls(
                              "bi bi-toggle-on",
                              matchSt.icon,
                              matchSt.success
                            )}
                          ></i>
                        ) : (
                          <i
                            className={cls(
                              "bi bi-toggle-off",
                              matchSt.icon,
                              matchSt.danger
                            )}
                          ></i>
                        )}
                      </button>
                    </td>
                    {genericList && (
                      <td>
                        {!!keyword.additional && (
                          <i
                            className={cls(
                              "bi bi-check-lg",
                              matchSt.icon,
                              matchSt.success
                            )}
                          ></i>
                        )}
                      </td>
                    )}
                    <td>
                      {!keyword.additional && !keyword.blacklisted && (
                        <button
                          className={matchSt.noBtn}
                          onClick={() => setShowDeleteWord(keyword)}
                        >
                          <i
                            className={cls(
                              "bi bi-trash-fill",
                              matchSt.icon,
                              matchSt.danger
                            )}
                          ></i>
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
      </div>
      {pager()}
      <AsyncModal
        show={showBulkDeleteWords}
        onSave={handleOnDeleteKeywordsList}
        onClose={() => setShowBulkDeleteWords(false)}
        title="Are you sure?"
        labelConfirm="Delete"
      >
        You are about to delete:
        <ul>
          {list
            .filter((el) => bulkSelected.find((b) => el.word === b))
            .map((selected) => (
              <li key={selected.word}>{selected.word}</li>
            ))}
        </ul>
      </AsyncModal>
      <AsyncModal
        show={showDeleteWord}
        onClose={() => setShowDeleteWord(undefined)}
        onSave={(k) => deleteKeyword(k.word)}
        title="Are you sure?"
        labelConfirm="Delete"
      >
        You are about to delete the keyword "{showDeleteWord?.word}"
      </AsyncModal>
      <ModalAddKeyword
        show={showAddKey}
        onClose={() => setShowAddKey(false)}
        onSave={handleOnSaveNewKeyword}
      />
    </div>
  );
};
export default KeywordsListTab;
