import { FC } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import st from "./dashboard.module.scss";
import KeywordsListTab from "./KeywordsListTab";
import PoisListTab from "./PoisListTab";

const TABS = ["pois", "keywords"];

const Dashboard: FC = () => {
  const { page } = useParams();
  const navigate = useNavigate();
  if (!page || !TABS.includes(page)) {
    return <Navigate replace to="/scraper/dashboard/pois" />;
  }
  return (
    <Container>
      <h1>Dashboard</h1>
      <Tabs
        id="controlled-tab-example"
        activeKey={page}
        onSelect={(k) => navigate(`/scraper/dashboard/${k}`)}
      >
        <Tab eventKey="pois" title="POIs">
          {page === "pois" && <PoisListTab className={st.tabContent} />}
        </Tab>
        <Tab eventKey="keywords" title="Keywords">
          {page === "keywords" && (
            <KeywordsListTab genericList className={st.tabContent} />
          )}
        </Tab>
      </Tabs>
    </Container>
  );
};

export default Dashboard;
