import { FC } from "react";
import LoginButton from "../../components/LoginButton";
import st from "./unauthorized.module.scss";
import { ReactComponent as UnauthorizedImage } from "./unauthorized.svg";

const Unauthorized: FC = () => {
  return (
    <div className={st.unauthorizedContainer}>
      <UnauthorizedImage className={st.unauthorizedImage} />
      <h1>Lightshift Dashboard Apps</h1>
      <p>
        This app is exclusive for Lightshift associates, Sign in to continue:
      </p>
      <LoginButton />
    </div>
  );
};

export default Unauthorized;
