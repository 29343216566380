import { ReactNode } from "react";
import { TableOrderDirection } from "src/interfaces/store";
import st from "./table-order-button.module.scss";

interface Props<T> {
  children: ReactNode;
  orderKey: T;
  order: T;
  orderDirection: TableOrderDirection;
  onClick: (direction: TableOrderDirection, key: T) => void;
  onCheck?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
}

const TableOrderButton = <T extends unknown>({
  children,
  orderKey,
  order,
  orderDirection,
  onClick,
  onCheck,
  checked,
}: Props<T>) => {
  const actualDirection = orderKey === order ? orderDirection : "DESC";

  const handleOnCheck = (
    ev: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    ev.stopPropagation();
  };

  return (
    <>
      <button
        className={st.orderButton}
        onClick={() => {
          onClick(actualDirection === "DESC" ? "ASC" : "DESC", orderKey);
        }}
      >
        {!!onCheck && (
          <input
            type="checkbox"
            onChange={onCheck}
            onClick={handleOnCheck}
            checked={checked}
          />
        )}
        {children}
        {orderKey === order ? (
          orderDirection === "DESC" ? (
            <i className="bi bi-caret-up-fill"></i>
          ) : (
            <i className="bi bi-caret-down-fill"></i>
          )
        ) : (
          ""
        )}
      </button>
    </>
  );
};
export default TableOrderButton;
