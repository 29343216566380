import { Store, TUpdateFunction } from "pullstate";
import { useCallback } from "react";
import { Token } from "src/interfaces/store";

function useUpdateSessionData(SessionStore: Store<Token>) {
  const func = useCallback(
    (
      data: Partial<Record<keyof Token, string | string[] | number>>,
      toRemove?: boolean
    ) => {
      const fns: TUpdateFunction<Token>[] = Object.entries(data).map(
        ([key, val]) =>
          (s: any) => {
            if (toRemove) {
              localStorage.removeItem(key);
            } else {
              if (typeof val === "number") {
                localStorage.setItem(key, val.toString());
              } else if (Array.isArray(val)) {
                localStorage.setItem(key, val.join(","));
              } else {
                localStorage.setItem(key, val);
              }
            }
            s[key] = val;
          }
      );
      SessionStore.update(fns);
    },
    [SessionStore]
  );

  return func;
}

export default useUpdateSessionData;
