import cls from "classnames";
import { FC, useState } from "react";
import { LeaderEntry } from "src/interfaces/store";
import st from "./ratio-chart.module.scss";

interface Props {
  entry: LeaderEntry;
}

const RatioChart: FC<Props> = ({ entry }) => {
  const [clicked, setClicked] = useState(true);

  const handleOnClick = (
    ev: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    ev.stopPropagation();
    if (entry.unscored !== entry.total) {
      setClicked((prev) => !prev);
    }
  };

  const total = !clicked ? entry.total : entry.total - entry.unscored;
  const unscored = (entry.unscored * 100) / entry.total;
  const blacklisted = (entry.blacklisted * 100) / total;
  const interested = (entry.interested * 100) / total;
  const invested = (entry.invested * 100) / total;
  const TFEB = (entry.TFEB * 100) / total;

  return (
    <div className={st.ratiosContainer}>
      <button className={st.ratioButton} onClick={handleOnClick}>
        {(!clicked || entry.unscored === entry.total) && (
          <div
            className={cls(st.chartBar, st.unscored)}
            style={{
              width: `${unscored + 0.01}%`,
            }}
            title={`Unscored: ${entry.unscored} (${(
              (entry.unscored * 100) /
              entry.total
            ).toFixed(0)}%)`}
          ></div>
        )}
        <div
          className={cls(st.chartBar, st.blacklisted)}
          style={{
            width: `${blacklisted}%`,
          }}
          title={`Blacklisted: ${entry.blacklisted} (${(
            (entry.blacklisted * 100) /
            entry.total
          ).toFixed(0)}%)`}
        ></div>
        <div
          className={cls(st.chartBar, st.interested)}
          style={{
            width: `${interested}%`,
          }}
          title={`Interested: ${entry.interested} (${(
            (entry.interested * 100) /
            entry.total
          ).toFixed(0)}%)`}
        ></div>
        <div
          className={cls(st.chartBar, st.invested)}
          style={{
            width: `${invested}%`,
          }}
          title={`Invested: ${entry.invested} (${(
            (entry.invested * 100) /
            entry.total
          ).toFixed(0)}%)`}
        ></div>
        <div
          className={cls(st.chartBar, st.tfeb)}
          style={{
            width: `${TFEB}%`,
          }}
          title={`TFEB: ${entry.TFEB} (${(
            (entry.TFEB * 100) /
            entry.total
          ).toFixed(0)}%)`}
        ></div>
      </button>
    </div>
  );
};

export default RatioChart;
