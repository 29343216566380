import { FC } from "react";
import { Grant } from "src/interfaces/gitCoin";
import st from "./grant-card.module.scss";

interface Props {
  grant: Grant;
}

const GrantCard: FC<Props> = ({ grant }) => {
  const USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });

  return (
    <a
      href={`https://bounties.gitcoin.co${grant.details_url}`}
      target="_blank"
      rel="noreferrer"
      className={st.cardLink}
    >
      <div className={st.grantCard}>
        <div className={st.picture}>
          <img src={grant.logo_url} alt={grant.title} />
        </div>
        <div className={st.info}>
          <h4>{grant.title}</h4>
          <span>
            by{" "}
            <a
              onClick={(ev) => ev.stopPropagation()}
              href={grant.admin_profile.url}
              target="_blank"
              rel="noreferrer"
            >
              {/* <img
                alt={grant.admin_profile.handle}
                src={grant.admin_profile.avatar_url}
              /> */}
              {grant.admin_profile.handle}{" "}
              {grant.verified ? <i className="bi bi-patch-check-fill"></i> : ""}
            </a>
          </span>
          <span>last updated: {grant.last_update_natural}</span>
          {/* <p>{grant.description}</p> */}
          <span>
            <span className={st.raised}>
              {USDollar.format(parseFloat(grant.amount_received))}
            </span>{" "}
            total raised
          </span>
        </div>
      </div>
    </a>
  );
};

export default GrantCard;
