import { useRouteError } from "react-router-dom";
import st from "./error-page.module.scss";
import { ReactComponent as ErrorImage } from "./error.svg";

interface GenericError {
  statusText?: string;
  message?: string;
  status?: string;
}

export default function ErrorPage() {
  const error = useRouteError() as GenericError;
  console.error(error);

  return (
    <div id="error-page" className={st.errorPage}>
      <ErrorImage className={st.errorImage} />
      <h1>Oops!</h1>
      <p>
        Sorry, an unexpected error has occurred.
        <br />
        <i>
          {error.status ? error.status + " " : ""}
          {error.statusText || error.message}
        </i>
      </p>
    </div>
  );
}
