import { FC, useState } from "react";
import { Form } from "react-bootstrap";
import { AddNewAccountResponse } from "src/api/usePoiApi";
import AsyncModal from "../AsyncModal";

interface Props {
  show: boolean;
  onClose: () => void;
  onSave: (accountUrl: string) => Promise<AddNewAccountResponse>;
}

const ModalAddAccount: FC<Props> = ({ show, onClose, onSave }) => {
  const [url, setUrl] = useState("");
  return (
    <AsyncModal
      show={show}
      onClose={onClose}
      onSave={() => {
        if (!url) {
          throw new Error("No url provided.");
        }
        return onSave(url);
      }}
      title="Add new account"
      labelConfirm="Save"
    >
      <Form.Label>New account URL</Form.Label>
      <Form.Control
        type="text"
        placeholder="http://twitter.com/..."
        value={url}
        onChange={(ev) => setUrl(ev.target.value)}
      />
    </AsyncModal>
  );
};

export default ModalAddAccount;
