import { useCallback, useEffect } from "react";
import { NavigateOptions, useSearchParams } from "react-router-dom";
import usePoiApi from "src/api/usePoiApi";
import {
  IntervalType,
  LeaderEntry,
  TableOrderDirection,
} from "src/interfaces/store";
import { LeaderStore } from "src/store";

type SetNumber = (key: number) => void;
type SetInterval = (key: number, type: IntervalType) => void;
type SetOrder = (dir: TableOrderDirection, key: keyof LeaderEntry) => void;

export default function useLeaderStore(): {
  setPage: SetNumber;
  setPageSize: SetNumber;
  setOrderBy: SetOrder;
  setInterval: SetInterval;
} {
  const { listLeaders } = usePoiApi();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page");
  const pageSize = searchParams.get("pageSize");
  const interval = searchParams.get("interval");
  const intervalType = searchParams.get("intervalType") as IntervalType;
  const orderBy = searchParams.get("orderBy") as keyof LeaderEntry | undefined;
  const orderDirection = searchParams.get("orderDirection") as
    | TableOrderDirection
    | undefined;

  const setPage = useCallback(
    (page: number, navigateOpts?: NavigateOptions) => {
      searchParams.set("page", page.toString());
      setSearchParams(searchParams, navigateOpts);
    },
    [searchParams, setSearchParams]
  );

  const setPageSize = useCallback(
    (pageSize: number) => {
      searchParams.set("pageSize", pageSize.toString());
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const setInterval = useCallback(
    (interval: number, intervalType: IntervalType) => {
      searchParams.set("interval", interval.toString());
      searchParams.set("intervalType", intervalType);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const setOrderBy = useCallback(
    (dir: TableOrderDirection, key: keyof LeaderEntry) => {
      searchParams.set("orderDirection", dir);
      searchParams.set("orderBy", key);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  useEffect(() => {
    // initializer
    if (!searchParams.has("page")) {
      setInterval(1, "WEEK");
      setOrderBy("DESC", "ratio");
      setPage(1, { replace: true });
    }
  }, [searchParams, setInterval, setOrderBy, setPage, setSearchParams]);

  useEffect(() => {
    if (!page) {
      return;
    }
    LeaderStore.update((s) => {
      s.loading = true;
    });
    const controller = new AbortController();
    (async () => {
      try {
        const search = {
          page: parseInt(page, 10),
          ...(pageSize ? { pageSize } : {}),
          ...(orderBy ? { orderBy } : {}),
          ...(orderDirection ? { orderDirection } : {}),
          ...(interval ? { interval } : {}),
          ...(intervalType ? { intervalType } : {}),
        };
        const result = await listLeaders(search, controller.signal);
        LeaderStore.update((s) => {
          s.list = result.list;
          s.page = result.page;
          s.totalAmount = result.totalAmount;
          s.pageSize = result.pageSize;
          s.orderBy = result.orderBy;
          s.orderDirection = result.orderDirection;
          s.interval = parseInt(interval || "1", 10);
          s.intervalType = intervalType || ("WEEK" as IntervalType);
          s.loading = false;
        });
      } catch (err) {
        if ((err as DOMException).name !== "AbortError") {
          LeaderStore.update((s) => {
            s.error = err as Error;
            s.loading = false;
          });
        }
      }
    })();
    return () => controller.abort();
  }, [
    page,
    pageSize,
    orderBy,
    orderDirection,
    intervalType,
    interval,
    listLeaders,
  ]);

  return {
    setPage,
    setPageSize,
    setOrderBy,
    setInterval,
  };
}
