import { FC, MutableRefObject, useCallback, useEffect, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import useKeywordApi from "src/api/useKeywordApi";
import MultiSelectSearch from "src/components/MultiSelectSearch";
import useDebounce from "src/hooks/useDebounce";

interface Props {
  removeWordFunc: MutableRefObject<(word: string) => void>;
  onChange: (keys: string) => void;
  update: boolean;
  style?: React.CSSProperties;
}

const KeywordsFilterInput: FC<Props> = ({
  removeWordFunc,
  onChange,
  update,
  style,
}) => {
  const [search, setSearch] = useState("");
  const { listKeywords } = useKeywordApi();
  const [searchParams] = useSearchParams();
  const selectedKeywords = searchParams.get("keywords") || "";

  const debSearch = useDebounce(search);
  const {
    data: searchKeywords,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteQuery(
    ["MatchesKeywordsList", debSearch],
    ({ pageParam = 1, signal }) =>
      listKeywords(
        {
          page: pageParam,
          keywords: debSearch,
        },
        signal
      ),
    {
      enabled: update,
      refetchOnWindowFocus: false,
      retry: false,
      getNextPageParam: (lastPage) =>
        lastPage.totalAmount > lastPage.page * lastPage.pageSize
          ? lastPage.page + 1
          : undefined,
    }
  );

  const handleOnClick = useCallback(
    (word: string) => {
      let res = selectedKeywords
        .trim()
        .split(",")
        .filter((k) => k !== "");
      if (res.includes(word)) {
        res = res.filter((f) => f !== word);
      } else {
        res.push(word);
      }
      onChange(res.join(","));
    },
    [selectedKeywords, onChange]
  );

  useEffect(() => {
    removeWordFunc.current = handleOnClick;
  }, [handleOnClick, removeWordFunc]);

  const handleOnChange = (query: string) => {
    setSearch(query);
  };

  return (
    <MultiSelectSearch
      label="Keywords"
      onChange={handleOnChange}
      value={search}
      loadingLabel={hasNextPage ? "Loading..." : ""}
      onLoadMore={() => {
        if (!isFetchingNextPage && !isLoading) {
          fetchNextPage();
        }
      }}
      wordsList={
        searchKeywords?.pages.reduce(
          (prev, add) => [...prev, ...add.list.map((key) => key.word)],
          [] as string[]
        ) || ([] as string[])
      }
      selectedWords={selectedKeywords.split(",")}
      onClick={handleOnClick}
      style={style}
    />
  );
};

export default KeywordsFilterInput;
