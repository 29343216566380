import cls from "classnames";
import { FC } from "react";
import { Container } from "react-bootstrap";
import usePoiApi from "src/api/usePoiApi";
import Spinner from "src/components/Spinner";
import useBiosAccountsStore from "src/hooks/endpoint/useBiosAccountsStore";
import { BiosAccount } from "src/interfaces/store";
import { AccountsBiosStore } from "src/store";
import { ReactComponent as DataNotFoundImage } from "../Matches/data-not-found.svg";
import matchesSt from "../Matches/matches.module.scss";
import st from "./accounts-bios.module.scss";

const AccountsBios: FC = () => {
  const { updateBioDiffChecked } = usePoiApi();
  const { refresh, setLoading } = useBiosAccountsStore();
  const loading = AccountsBiosStore.useState((s) => s.loading);
  const list = AccountsBiosStore.useState((s) => s.list);

  const handleOnMarkReadClick = async (
    ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    account: BiosAccount
  ) => {
    ev.stopPropagation();
    setLoading(true);
    try {
      await updateBioDiffChecked({
        accountId: account.accountId,
        accountType: account.type,
      });
      refresh();
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  return (
    <Container>
      <h1>New Bios by Accounts</h1>
      <div className={matchesSt.tableContainer}>
        <Spinner className={matchesSt.spinner} show={loading} />
      </div>
      {!loading && (!list || list.length === 0) ? (
        <div className={matchesSt.emptyState}>
          <DataNotFoundImage className={matchesSt.noDataImage} />
          <br />
          There are no new bio updates.
        </div>
      ) : (
        <div className={cls(st.biosListContainer, { [st.loading]: loading })}>
          {(list || []).map((account) => (
            <div
              className={st.account}
              key={`${account.accountId}${account.type}`}
            >
              <div className={st.accountHeader}>
                <div>
                  <a href={account.url} target="_blank" rel="noreferrer">
                    <h3>{account.name}</h3>
                  </a>
                  <h5>
                    ID: {account.accountId} <span>({account.type})</span>
                  </h5>
                </div>
                <div>
                  <button
                    title="mark as read"
                    onClick={(ev) => handleOnMarkReadClick(ev, account)}
                    className={st.markAsRead}
                  >
                    <i className="bi bi-eye-fill"></i>
                  </button>
                </div>
              </div>
              <div className={st.accountDescriptions}>
                {account.descriptions.map((desc) => (
                  <div
                    key={`${account.accountId}${account.type}${desc.updatedDate}`}
                  >
                    <h5>{new Date(desc.updatedDate).toLocaleDateString()}</h5>
                    <p>{desc.description}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </Container>
  );
};
export default AccountsBios;
