import cls from "classnames";
import { FC, useState } from "react";
import { Table } from "react-bootstrap";
import { GenericResponse } from "src/interfaces/api";
import { GenericKeyword } from "src/interfaces/store";
import { ReactComponent as DataNotFoundImage } from "../../pages/Matches/data-not-found.svg";
import AsyncModal from "../Modal/AsyncModal";
import st from "./keywords-table.module.scss";

interface Props {
  keywords: GenericKeyword[];
  onRemove: (keyword: GenericKeyword) => Promise<GenericResponse>;
  onUpdateIsolated: (word: string, isolated: boolean) => void;
  disabled?: boolean;
  onUpdateMatchInDescription: (
    word: string,
    matchInDescription: boolean
  ) => void;
}

const KeywordsTable: FC<Props> = ({
  keywords,
  onRemove,
  onUpdateIsolated,
  onUpdateMatchInDescription,
  disabled,
}) => {
  const [showDeleteWord, setShowDeleteWord] = useState<GenericKeyword>();
  return (
    <>
      <Table className={st.table}>
        <thead>
          <tr>
            <th>Word</th>
            <th>Isolated</th>
            <th>Match in description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {(!!keywords && !keywords) ||
            (keywords.length === 0 && (
              <tr>
                <td className={st.emptyState} colSpan={4}>
                  <DataNotFoundImage className={st.noDataImage} />
                  <br />
                  There is no data to display.
                </td>
              </tr>
            ))}
          {keywords.map((keyword) => {
            return (
              <tr key={`${keyword.word}`}>
                <td>{keyword.word}</td>
                <td>
                  <button
                    className={st.noBtn}
                    disabled={disabled}
                    onClick={(ev) => {
                      ev.stopPropagation();
                      onUpdateIsolated(keyword.word, !keyword.isolated);
                    }}
                  >
                    {keyword.isolated ? (
                      <i
                        className={cls("bi bi-toggle-on", st.icon, st.success)}
                      ></i>
                    ) : (
                      <i
                        className={cls("bi bi-toggle-off", st.icon, st.danger)}
                      ></i>
                    )}
                  </button>
                </td>
                <td>
                  <button
                    className={st.noBtn}
                    disabled={disabled}
                    onClick={(ev) => {
                      ev.stopPropagation();
                      onUpdateMatchInDescription(
                        keyword.word,
                        !keyword.matchInDescription
                      );
                    }}
                  >
                    {keyword.matchInDescription ? (
                      <i
                        className={cls("bi bi-toggle-on", st.icon, st.success)}
                      ></i>
                    ) : (
                      <i
                        className={cls("bi bi-toggle-off", st.icon, st.danger)}
                      ></i>
                    )}
                  </button>
                </td>
                <td>
                  <button
                    className={st.noBtn}
                    disabled={disabled}
                    onClick={() => setShowDeleteWord(keyword)}
                  >
                    <i
                      className={cls("bi bi-trash-fill", st.icon, st.danger)}
                    ></i>
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
        <AsyncModal
          show={showDeleteWord}
          onClose={() => setShowDeleteWord(undefined)}
          onSave={onRemove}
          title="Are you sure?"
          labelConfirm="Delete"
        >
          You are about to delete the keyword "{showDeleteWord?.word}"
        </AsyncModal>
      </Table>
    </>
  );
};

export default KeywordsTable;
