import cls from "classnames";
import { ReactNode, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Modal as BootstrapModal,
  Spinner,
} from "react-bootstrap";
import { GenericResponse } from "src/interfaces/api";
import st from "./async-modal.module.scss";

interface Props<T> {
  show: T | undefined;
  onClose: () => void;
  onSave: (arg: T) => Promise<GenericResponse>;
  onError?: (err: Error) => void;
  title: ReactNode;
  children: ReactNode;
  labelConfirm: string;
  noCloseOnConfirm?: boolean;
  onModalClick?: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const AsyncModal = <T extends unknown>({
  show,
  onClose,
  onSave,
  title,
  children,
  labelConfirm,
  onError,
  noCloseOnConfirm,
  onModalClick,
}: Props<T>) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!!error) setError(undefined);
    }, 15000);
    return () => clearTimeout(timeout);
  }, [error]);

  useEffect(() => {
    if (loading) {
      setError(undefined);
    }
  }, [loading]);

  const handleOnConfirmClick = async () => {
    setLoading(true);
    try {
      const resp = await onSave(show as T);
      if (resp.status === "success" && !noCloseOnConfirm) {
        onClose();
      }
      if (resp.status === "error") {
        throw new Error(resp.message || "Something went wrong.");
      }
    } catch (e) {
      if (onError) {
        onError(e as Error);
      } else {
        setError(e as Error);
      }
    }
    setLoading(false);
  };

  return (
    <BootstrapModal
      onClick={onModalClick}
      show={!!show}
      onHide={loading ? () => {} : onClose}
    >
      <BootstrapModal.Header closeButton={!loading}>
        <BootstrapModal.Title>{title}</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        {children}
        {!!error && (
          <Alert
            className={st.errorAlert}
            variant="danger"
            onClose={() => setError(undefined)}
            dismissible
          >
            {error.message}
          </Alert>
        )}
      </BootstrapModal.Body>
      <BootstrapModal.Footer className={st.footer}>
        <div className={cls(st.loading, { [st.visible]: loading })}>
          <Spinner />
        </div>
        <Button variant="secondary" disabled={loading} onClick={onClose}>
          Close
        </Button>
        <Button
          variant="primary"
          disabled={loading}
          onClick={handleOnConfirmClick}
        >
          {labelConfirm}
        </Button>
      </BootstrapModal.Footer>
    </BootstrapModal>
  );
};

export default AsyncModal;
