import { useCallback, useEffect } from "react";
import { NavigateOptions, useSearchParams } from "react-router-dom";
import { GenericResponse } from "src/interfaces/api";
import { Match, MatchScore, TableOrderDirection } from "src/interfaces/store";
import useMatchApi from "../../api/useMatchApi";
import { MatchStore } from "../../store";

type SetString = (key: string) => void;
type SetNumber = (key: number) => void;
type SetOrder = (dir: TableOrderDirection, key: keyof Match) => void;

type SetFilterBy = (key: keyof Match, value: string) => void;

export default function useMatchesStore(): {
  setPage: SetNumber;
  setKeywords: SetString;
  setAccounts: SetString;
  setPageSize: SetNumber;
  setOrderBy: SetOrder;
  updateMatchRead: (matchId: number[], read: boolean) => void;
  updateMatchScore: (
    matchId: number[],
    score?: MatchScore
  ) => Promise<GenericResponse>;
  updateFilterBy: SetFilterBy;
} {
  const { listMatches, updateMatch } = useMatchApi();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page");
  const keywords = searchParams.get("keywords");
  const accounts = searchParams.get("accounts");
  const pageSize = searchParams.get("pageSize");
  const filterBy = searchParams.get("filterBy");
  const orderBy = searchParams.get("orderBy") as keyof Match | undefined;
  const orderDirection = searchParams.get("orderDirection") as
    | TableOrderDirection
    | undefined;

  const updateFilterBy = useCallback(
    (key: string, value: string) => {
      const oldFilterBy = JSON.parse(searchParams.get("filterBy") || "{}");
      let filterBy: Record<string, string> = { ...oldFilterBy };
      if (value !== "") {
        filterBy[key] = value;
      } else {
        let { [key]: _, ...newFilterBy } = filterBy;
        filterBy = newFilterBy;
      }
      if (Object.keys(filterBy).length === 0) {
        searchParams.delete("filterBy");
      } else {
        searchParams.set("filterBy", JSON.stringify(filterBy));
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const setPage = useCallback(
    (page: number, navigateOpts?: NavigateOptions) => {
      searchParams.set("page", page.toString());
      setSearchParams(searchParams, navigateOpts);
    },
    [searchParams, setSearchParams]
  );
  const setKeywords = useCallback(
    (keywords: string) => {
      if (keywords !== "") {
        searchParams.set("keywords", keywords);
      } else {
        searchParams.delete("keywords");
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );
  const setAccounts = useCallback(
    (accounts: string) => {
      if (accounts !== "") {
        searchParams.set("accounts", accounts);
      } else {
        searchParams.delete("accounts");
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );
  const setPageSize = useCallback(
    (pageSize: number) => {
      searchParams.set("pageSize", pageSize.toString());
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const setOrderBy = useCallback(
    (dir: TableOrderDirection, key: keyof Match) => {
      searchParams.set("orderDirection", dir);
      searchParams.set("orderBy", key);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  useEffect(() => {
    // initializer
    if (!searchParams.has("page")) {
      setPage(1, { replace: true });
    }
  }, [searchParams, setPage, setSearchParams]);

  const updateMatchRead = useCallback(
    async (matchIds: number[], read: boolean) => {
      try {
        await updateMatch({
          body: {
            values: { read },
            matchIds,
          },
        });
        MatchStore.update((s) => {
          const auxList = [...s.list];
          const matches = auxList.filter((value) =>
            matchIds.includes(value.matchId)
          );
          matches.forEach((match) => (match.read = read));
          s.list = auxList;
        });
      } catch (e) {
        console.error(e);
      }
    },
    [updateMatch]
  );

  const updateMatchScore = useCallback(
    async (matchIds: number[], score?: MatchScore) => {
      const resp = await updateMatch({
        body: {
          values: score ? { score } : undefined,
          matchIds,
        },
      });
      if (score) {
        MatchStore.update((s) => {
          const auxList = [...s.list];
          const matches = auxList.filter((value) =>
            matchIds.includes(value.matchId)
          );
          matches.forEach((match) => (match.score = score));
          s.list = auxList;
        });
      }
      return resp;
    },
    [updateMatch]
  );

  useEffect(() => {
    if (!page) {
      return;
    }
    MatchStore.update((s) => {
      s.loading = true;
    });
    const controller = new AbortController();
    (async () => {
      try {
        const fb = JSON.parse(filterBy || "{}");
        const search = {
          page: parseInt(page, 10),
          ...(keywords ? { keywords } : {}),
          ...(accounts ? { accounts } : {}),
          ...(pageSize ? { pageSize } : {}),
          ...(orderBy ? { orderBy } : {}),
          ...(orderDirection ? { orderDirection } : {}),
          ...(filterBy ? { filterBy } : {}),
        };
        const result = await listMatches(search, controller.signal);
        MatchStore.update((s) => {
          s.list = result.list;
          s.page = result.page;
          s.totalAmount = result.totalAmount;
          s.pageSize = result.pageSize;
          s.orderBy = result.orderBy;
          s.orderDirection = result.orderDirection;
          s.filterBy = Object.keys(fb).length > 0 ? fb : null;
          s.loading = false;
        });
      } catch (err) {
        console.error(err);
        if ((err as DOMException).name !== "AbortError") {
          MatchStore.update((s) => {
            s.error = err as Error;
            s.loading = false;
          });
        }
      }
    })();
    return () => controller.abort();
  }, [
    accounts,
    filterBy,
    keywords,
    listMatches,
    orderBy,
    orderDirection,
    page,
    pageSize,
  ]);

  return {
    setPage,
    setKeywords,
    setAccounts,
    setPageSize,
    setOrderBy,
    updateMatchRead,
    updateMatchScore,
    updateFilterBy,
  };
}
