import cls from "classnames";
import { FC, useEffect, useRef, useState } from "react";
import {
  Badge,
  Button,
  Container,
  Dropdown,
  Form,
  Table,
} from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import ModalChangeScore from "src/components/Modal/ModalChangeScore";
import ScoreButton from "src/components/ScoreButton";
import Spinner from "src/components/Spinner";
import TableOrderButton from "src/components/TableOrderButton";
import {
  BotTypeValues,
  Match,
  MatchScore,
  MatchScoresProps,
  TableOrderDirection,
} from "src/interfaces/store";
import TablePager from "../../components/TablePager";
import useMatchesStore from "../../hooks/endpoint/useMatchesStore";
import { MatchStore } from "../../store";
import AccountsFilterInput from "./AccountsFilterInput";
import { ReactComponent as DataNotFoundImage } from "./data-not-found.svg";
import KeywordsFilterInput from "./KeywordsFilterInput";
import st from "./matches.module.scss";

const Matches: FC = () => {
  const [searchParams] = useSearchParams();
  const {
    setPage,
    setPageSize,
    setOrderBy,
    updateMatchRead,
    updateMatchScore,
    setKeywords,
    setAccounts,
    updateFilterBy,
  } = useMatchesStore();
  const loading = MatchStore.useState((s) => s.loading);
  const list = MatchStore.useState((s) => s.list);
  const page = MatchStore.useState((s) => s.page);
  const total = MatchStore.useState((s) => s.totalAmount);
  const orderBy = MatchStore.useState((s) => s.orderBy);
  const orderDirection = MatchStore.useState((s) => s.orderDirection);
  const pageSize = searchParams.get("pageSize");
  const keywords = searchParams.get("keywords");
  const accounts = searchParams.get("accounts");
  const filterBy = JSON.parse(searchParams.get("filterBy") || "{}");
  const [searchUrl, setSearchUrl] = useState(filterBy["url"] || "");
  const [showFilter, setShowFilter] = useState(false);
  const [showBulkScore, setShowBulkScore] = useState(false);
  const [bulkScore, setBulkScore] = useState<MatchScore>();
  const removeBadgeKeywordFn = useRef((word: string) => {});
  const removeBadgeAccountFn = useRef((accountId: string) => {});
  const [bulkSelected, setBulkSelected] = useState<number[]>([]);
  const shiftKey = useRef(false);
  const navigate = useNavigate();

  const handleOnUpdateOrder = (dir: TableOrderDirection, key: keyof Match) => {
    setOrderBy(dir, key);
  };

  const handleOnRowClick = (matchId: number) => {
    navigate(`/scraper/matches/${matchId}`);
  };

  const handleOnSelectedRow = (
    ev: React.ChangeEvent<HTMLInputElement>,
    matchId: number
  ) => {
    if (ev.target.checked) {
      if (shiftKey.current) {
        setBulkSelected((prev) => {
          const allEls = list.map((el) => el.matchId);
          const firstIndex = allEls.indexOf(prev.slice(-1)[0]);
          const secIndex = allEls.indexOf(matchId);
          const finalArr = allEls.slice(
            firstIndex < secIndex ? firstIndex : secIndex,
            firstIndex > secIndex ? firstIndex : secIndex + 1
          );
          return prev.concat(
            finalArr.filter((item) => prev.indexOf(item) === -1)
          );
        });
      } else {
        setBulkSelected((prev) => [...prev, matchId]);
      }
    } else {
      setBulkSelected((prev) => prev.filter((el) => el !== matchId));
    }
  };

  const handleOnSelectAll = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (ev.target.checked) {
      setBulkSelected(list.map((match) => match.matchId));
    } else {
      setBulkSelected([]);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      updateFilterBy("url", searchUrl);
    }, 500);
    return () => clearTimeout(timeout);
  }, [searchUrl, updateFilterBy]);

  const bulkActionSelected = (ev: string | null) => {
    if (ev === "read" || ev === "unread") {
      updateMatchRead(bulkSelected, ev === "read");
    } else if (ev === "score") {
      setShowBulkScore(true);
    }
  };

  const handleOnSaveBulkScore = () => updateMatchScore(bulkSelected, bulkScore);

  const pager = (showActions?: boolean) => (
    <TablePager
      total={total as number}
      page={page as number}
      pageSize={parseInt(pageSize || "20", 10)}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
      actionButtons={
        showActions ? (
          <>
            <Button onClick={() => setShowFilter((prev) => !prev)}>
              {showFilter ? (
                <i className="bi bi-funnel"></i>
              ) : (
                <i className="bi bi-funnel-fill"></i>
              )}
            </Button>
            <Form.Control
              value={searchUrl}
              onChange={(ev) => setSearchUrl(ev.target.value)}
              placeholder="url search"
            />
            <Form.Group>
              <Dropdown onSelect={bulkActionSelected}>
                <Dropdown.Toggle
                  disabled={bulkSelected.length === 0}
                  variant="primary"
                  id="dropdown-bulk"
                >
                  Bulk Actions
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="read">Read</Dropdown.Item>
                  <Dropdown.Item eventKey="unread">Unread</Dropdown.Item>
                  <Dropdown.Item eventKey="score">Score</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>
          </>
        ) : undefined
      }
    />
  );

  return (
    <Container>
      <h1>Matches</h1>
      <div className={st.filterResults}>
        {keywords
          ?.split(",")
          .filter((e) => e !== "")
          .sort()
          .map((key) => (
            <Badge key={key}>
              {key}{" "}
              <button
                className={st.filterBadge}
                onClick={() => removeBadgeKeywordFn.current(key)}
              >
                <i className="bi bi-x"></i>
              </button>
            </Badge>
          ))}
        {accounts
          ?.split(",")
          .filter((e) => e !== "")
          .sort()
          .map((acc) => (
            <Badge bg="info" key={acc}>
              {acc}{" "}
              <button
                className={st.filterBadge}
                onClick={() => removeBadgeAccountFn.current(acc)}
              >
                <i className="bi bi-x"></i>
              </button>
            </Badge>
          ))}

        {filterBy["botType"] && (
          <Badge bg="warning">
            {filterBy["botType"].charAt(0).toUpperCase() +
              filterBy["botType"].slice(1)}
            <button
              className={st.filterBadge}
              onClick={() => updateFilterBy("botType", "")}
            >
              <i className="bi bi-x"></i>
            </button>
          </Badge>
        )}

        {filterBy["read"] === "true" ? (
          <Badge bg="warning">
            Only read{" "}
            <button
              className={st.filterBadge}
              onClick={() => updateFilterBy("read", "")}
            >
              <i className="bi bi-x"></i>
            </button>
          </Badge>
        ) : filterBy["read"] === "false" ? (
          <Badge bg="warning">
            Not read{" "}
            <button
              className={st.filterBadge}
              onClick={() => updateFilterBy("read", "")}
            >
              <i className="bi bi-x"></i>
            </button>
          </Badge>
        ) : (
          ""
        )}
        {filterBy["score"] && (
          <Badge bg="warning">
            {filterBy["score"].charAt(0).toUpperCase() +
              filterBy["score"].slice(1)}
            <button
              className={st.filterBadge}
              onClick={() => updateFilterBy("score", "")}
            >
              <i className="bi bi-x"></i>
            </button>
          </Badge>
        )}
      </div>
      <div
        className={cls(st.filtersContainer, { [st.showFilters]: showFilter })}
      >
        <div className={st.filterContent}>
          <div className={st.filterInputs}>
            <KeywordsFilterInput
              onChange={setKeywords}
              removeWordFunc={removeBadgeKeywordFn}
              update={showFilter}
            />
            <Form.Group>
              <Form.Label>Bot type</Form.Label>
              <Dropdown onSelect={(ev) => updateFilterBy("botType", ev || "")}>
                <Dropdown.Toggle
                  className={st.toggleFilter}
                  variant="outline-primary"
                  id="dropdown-bot-type"
                >
                  {!!filterBy["botType"]
                    ? filterBy["botType"].charAt(0).toUpperCase() +
                      filterBy["botType"].slice(1)
                    : "All"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {BotTypeValues.map((type) => (
                    <Dropdown.Item key={type} eventKey={type}>
                      {type.charAt(0).toUpperCase() + type.slice(1)}
                    </Dropdown.Item>
                  ))}
                  <Dropdown.Item eventKey="">All</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>
            <Form.Group>
              <Form.Label>Read</Form.Label>
              <Dropdown onSelect={(ev) => updateFilterBy("read", ev || "")}>
                <Dropdown.Toggle
                  className={st.toggleFilter}
                  variant="outline-primary"
                  id="dropdown-read"
                >
                  {filterBy["read"] === "true"
                    ? "Only read"
                    : filterBy["read"] === "false"
                    ? "Not read"
                    : "All"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey="true">Only read</Dropdown.Item>
                  <Dropdown.Item eventKey="false">Not read</Dropdown.Item>
                  <Dropdown.Item eventKey="">All</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>
            <Form.Group>
              <Form.Label>Score</Form.Label>
              <Dropdown onSelect={(ev) => updateFilterBy("score", ev || "")}>
                <Dropdown.Toggle
                  className={st.toggleFilter}
                  variant="outline-primary"
                  id="dropdown-read-t"
                >
                  {!filterBy["score"] || filterBy["score"] === ""
                    ? "All"
                    : filterBy["score"].charAt(0).toUpperCase() +
                      filterBy["score"].slice(1)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey="">All</Dropdown.Item>
                  {Object.entries(MatchScoresProps).map(([key, { title }]) => (
                    <Dropdown.Item key={key} eventKey={key}>
                      {title}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>
            <AccountsFilterInput
              onChange={setAccounts}
              removeAccountFunc={removeBadgeAccountFn}
              update={showFilter}
            />
          </div>
        </div>
      </div>
      {pager(true)}
      <div className={st.tableContainer}>
        <Spinner className={st.spinner} show={loading} />
        <Table
          responsive="lg"
          className={cls(st.table, { [st.loading]: loading })}
        >
          <thead>
            <tr>
              <th>
                <TableOrderButton
                  onClick={handleOnUpdateOrder}
                  orderDirection={orderDirection}
                  orderKey="matchId"
                  order={orderBy}
                  onCheck={handleOnSelectAll}
                  checked={(list || []).length === bulkSelected.length}
                >
                  ID
                </TableOrderButton>
              </th>
              <th>
                <TableOrderButton
                  onClick={handleOnUpdateOrder}
                  orderDirection={orderDirection}
                  orderKey="url"
                  order={orderBy}
                >
                  Match
                </TableOrderButton>
              </th>
              <th>
                <TableOrderButton
                  onClick={handleOnUpdateOrder}
                  orderDirection={orderDirection}
                  orderKey="lastMatchDate"
                  order={orderBy}
                >
                  Match date
                </TableOrderButton>
              </th>
              <th>
                <TableOrderButton
                  onClick={handleOnUpdateOrder}
                  orderDirection={orderDirection}
                  orderKey="score"
                  order={orderBy}
                >
                  Score
                </TableOrderButton>
              </th>
              <th>
                <TableOrderButton
                  onClick={handleOnUpdateOrder}
                  orderDirection={orderDirection}
                  orderKey="read"
                  order={orderBy}
                >
                  Read
                </TableOrderButton>
              </th>
            </tr>
          </thead>
          <tbody>
            {!loading && page > 0 && (!list || list.length === 0) ? (
              <tr>
                <td className={st.emptyState} colSpan={6}>
                  <DataNotFoundImage className={st.noDataImage} />
                  <br />
                  There is no data to display.
                </td>
              </tr>
            ) : (
              !!list &&
              list.map((match) => {
                return (
                  <tr
                    className={st.rowEntry}
                    onClick={() => handleOnRowClick(match.matchId)}
                    key={match.matchId}
                  >
                    <td>
                      <div className={st.idTable}>
                        <input
                          checked={bulkSelected.some(
                            (el) => el === match.matchId
                          )}
                          onClick={(ev) => {
                            ev.stopPropagation();
                            shiftKey.current = ev.shiftKey;
                          }}
                          type="checkbox"
                          onChange={(ev) =>
                            handleOnSelectedRow(ev, match.matchId)
                          }
                        />
                        {match.matchId}
                      </div>
                    </td>
                    <td>
                      <a
                        href={match.url}
                        onClick={(ev) => {
                          ev.stopPropagation();
                          updateMatchRead([match.matchId], true);
                        }}
                        target="_blank"
                        rel="noreferrer"
                        className={st.truncate}
                      >
                        {!!match.name
                          ? `${match.name}${
                              !!match.position ? ` as ${match.position}` : ""
                            }${!!match.company ? ` at ${match.company}` : ""}`
                          : match.url}
                      </a>
                    </td>
                    <td className={st.matchDateColumn}>
                      {match.initialMatchDate === match.lastMatchDate &&
                        `${new Date(
                          match.initialMatchDate
                        ).toLocaleDateString()} ${new Date(
                          match.initialMatchDate
                        ).toLocaleTimeString()}`}
                      {match.initialMatchDate !== match.lastMatchDate && (
                        <>
                          Initial:{" "}
                          {new Date(
                            match.initialMatchDate
                          ).toLocaleDateString()}{" "}
                          {new Date(
                            match.initialMatchDate
                          ).toLocaleTimeString()}
                          <br />
                          Last:{" "}
                          {new Date(
                            match.lastMatchDate
                          ).toLocaleDateString()}{" "}
                          {new Date(match.lastMatchDate).toLocaleTimeString()}
                        </>
                      )}
                    </td>
                    <td>
                      <div className={st.scoreChart}>
                        <ScoreButton
                          changeScore={(match, value) =>
                            updateMatchScore([match.matchId], value)
                          }
                          match={match}
                        />
                      </div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <button
                        className={st.noBtn}
                        onClick={(ev) => {
                          ev.stopPropagation();
                          updateMatchRead([match.matchId], !match.read);
                        }}
                      >
                        {match.read ? (
                          <i
                            className={cls(
                              "bi bi-eye-fill",
                              st.icon,
                              st.success
                            )}
                          ></i>
                        ) : (
                          <i
                            className={cls(
                              "bi bi-eye-slash-fill",
                              st.icon,
                              st.danger
                            )}
                          ></i>
                        )}
                      </button>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
      </div>
      {pager()}
      <ModalChangeScore
        title="Change score to selected matches"
        onHide={() => setShowBulkScore(false)}
        show={showBulkScore}
        onChange={setBulkScore}
        onSave={handleOnSaveBulkScore}
        value={bulkScore}
      />
    </Container>
  );
};

export default Matches;
