import { useCallback } from "react";
import { GenericResponse, IListKeywordRequest } from "src/interfaces/api";
import { KeywordList } from "../interfaces/store";
import useApi from "./useApi";
interface IUpdateKeywordsRequest {
  word: string;
  body: Record<string, string | number | boolean>;
}

interface IAddKeywordRequest {
  word: string;
  isolated: boolean;
  matchInDescription: boolean;
}

interface IRemoveKeywordRequest {
  word: string;
}

interface IRemoveKeywordsRequest {
  keywords: string[];
}

type IListKeywords = (
  params: IListKeywordRequest,
  signal?: AbortSignal
) => Promise<KeywordList>;
type IUpdateKeyword = (
  params: IUpdateKeywordsRequest,
  signal?: AbortSignal
) => Promise<GenericResponse>;
type IAddKeyword = (
  params: IAddKeywordRequest,
  signal?: AbortSignal
) => Promise<GenericResponse>;
type IRemoveKeyword = (
  params: IRemoveKeywordRequest,
  signal?: AbortSignal
) => Promise<GenericResponse>;
type IRemoveKeywords = (
  params: IRemoveKeywordsRequest,
  signal?: AbortSignal
) => Promise<GenericResponse>;

export default function useKeywordApi(): {
  listKeywords: IListKeywords;
  updateKeyword: IUpdateKeyword;
  addKeyword: IAddKeyword;
  removeKeyword: IRemoveKeyword;
  removeKeywordsList: IRemoveKeywords;
} {
  const request = useApi();

  const updateKeyword: IUpdateKeyword = useCallback(
    ({ word, body }, signal) =>
      request({
        endpoint: `/keywords/${word}`,
        method: "PATCH",
        body: JSON.stringify(body),
        signal,
      }),
    [request]
  );

  const addKeyword: IAddKeyword = useCallback(
    ({ word, isolated, matchInDescription }, signal) =>
      request({
        endpoint: `/keywords`,
        method: "PUT",
        body: JSON.stringify({
          word,
          isolated,
          matchInDescription,
        }),
        signal,
      }),
    [request]
  );

  const removeKeyword: IRemoveKeyword = useCallback(
    ({ word }, signal) =>
      request({
        endpoint: `/keywords/${word}`,
        method: "DELETE",
        signal,
      }),
    [request]
  );

  const removeKeywordsList: IRemoveKeywords = useCallback(
    ({ keywords }, signal) =>
      request({
        endpoint: "/keywords",
        method: "DELETE",
        body: JSON.stringify({ keywords }),
        signal,
      }),
    [request]
  );

  const listKeywords: IListKeywords = useCallback(
    (
      { page, keywords, pageSize, orderBy, orderDirection, ignorePagination },
      signal
    ) =>
      request({
        endpoint: "/keywords",
        method: "GET",
        search: {
          page: page.toString(),
          ...(keywords ? { keywords } : {}),
          ...(pageSize ? { pageSize } : {}),
          ...(orderBy ? { orderBy } : {}),
          ...(orderDirection ? { orderDirection } : {}),
          ...(ignorePagination ? { ignorePagination: "true" } : {}),
        },
        signal,
      }),
    [request]
  );
  return {
    listKeywords,
    updateKeyword,
    addKeyword,
    removeKeyword,
    removeKeywordsList,
  };
}
