import cls from "classnames";
import { FC, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import usePoiApi from "src/api/usePoiApi";
import { Account, AccountDescription } from "src/interfaces/store";
import { ReactComponent as DataNotFoundImage } from "../../pages/Matches/data-not-found.svg";
import AsyncModal from "../Modal/AsyncModal";
import st from "./accounts-table.module.scss";

interface Props {
  accounts: Account[];
  onUpdate: (account: Account, update: Record<string, boolean>) => void;
  onDelete: (account: Account) => void;
  disabled?: boolean;
}

const AccountsTable: FC<Props> = ({
  accounts,
  onUpdate,
  onDelete,
  disabled,
}) => {
  const { updateAccountActive, updateAccountToScrape, deleteAccount } =
    usePoiApi();
  const [showDeleteAccount, setShowDeleteAccount] = useState<Account>();

  const [showModal, setShowModal] = useState<{
    accountId: string;
    type: string;
    name: string;
    descriptions: AccountDescription[];
    show: boolean;
  }>({ accountId: "", type: "", name: "", descriptions: [], show: false });

  const handleOnDescriptionsShow = (
    accountId: string,
    type: string,
    name: string,
    descriptions: AccountDescription[]
  ) => {
    setShowModal({ accountId, type, name, descriptions, show: true });
  };

  const handleOnToggleActive = async (account: Account) => {
    await updateAccountActive({
      accountId: account.accountId,
      accountType: account.type,
      active: !account.active,
    });
    onUpdate(account, { active: !account.active });
  };

  const handleOnToggleToScrape = async (account: Account) => {
    await updateAccountToScrape({
      accountId: account.accountId,
      accountType: account.type,
      toScrape: !account.toScrape,
    });
    onUpdate(account, { toScrape: !account.toScrape });
  };

  const handleOnDeleteAccount = async (account: Account) => {
    const resp = await deleteAccount({
      accountId: account.accountId,
      accountType: account.type,
    });
    if (resp.status === "success") {
      onDelete(account);
      setShowDeleteAccount(undefined);
    }
    return resp;
  };

  return (
    <>
      <Table className={st.table}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Type</th>
            <th>Name</th>
            <th>Scrape</th>
            <th>Active</th>
            <th>Descriptions</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {!accounts ||
            (accounts.length === 0 && (
              <tr>
                <td className={st.emptyState} colSpan={7}>
                  <DataNotFoundImage className={st.noDataImage} />
                  <br />
                  There is no data to display.
                </td>
              </tr>
            ))}
          {!!accounts &&
            accounts.map((account) => {
              return (
                <tr key={`${account.accountId}_${account.type}`}>
                  <td>
                    <Link to={`/scraper?page=1&accounts=${account.accountId}`}>
                      <span
                        title={account.accountId}
                        className={st.limitedTextSize}
                      >
                        {account.accountId}
                      </span>
                    </Link>
                  </td>
                  <td>
                    <a href={account.url} target="_blank" rel="noreferrer">
                      {account.type}
                    </a>
                  </td>
                  <td>
                    <a href={account.url} target="_blank" rel="noreferrer">
                      {account.name}
                    </a>
                  </td>
                  <td>
                    <button
                      onClick={() => handleOnToggleToScrape(account)}
                      className={st.noBtn}
                      disabled={disabled}
                    >
                      {account.toScrape ? (
                        <i
                          className={cls(
                            "bi bi-toggle-on",
                            st.icon,
                            st.success
                          )}
                        ></i>
                      ) : (
                        <i
                          className={cls(
                            "bi bi-toggle-off",
                            st.icon,
                            st.danger
                          )}
                        ></i>
                      )}
                    </button>
                  </td>
                  <td>
                    <button
                      onClick={() => handleOnToggleActive(account)}
                      className={st.noBtn}
                      disabled={disabled}
                    >
                      {account.active ? (
                        <i
                          className={cls(
                            "bi bi-toggle-on",
                            st.icon,
                            st.success
                          )}
                        ></i>
                      ) : (
                        <i
                          className={cls(
                            "bi bi-toggle-off",
                            st.icon,
                            st.danger
                          )}
                        ></i>
                      )}
                    </button>
                  </td>
                  <td>
                    <button
                      className={st.noBtn}
                      title="Descriptions"
                      disabled={disabled}
                      onClick={() =>
                        handleOnDescriptionsShow(
                          account.accountId,
                          account.type,
                          account.name,
                          account.descriptions
                        )
                      }
                    >
                      <i className={cls("bi bi-body-text", st.icon)}></i>
                    </button>
                  </td>
                  <td>
                    <button
                      title="delete"
                      className={st.noBtn}
                      disabled={disabled}
                      onClick={() => setShowDeleteAccount(account)}
                    >
                      <i
                        className={cls("bi bi-trash-fill", st.icon, st.danger)}
                      ></i>
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <Modal
        show={showModal?.show}
        onHide={() => {
          setShowModal((prev) => ({ ...prev, show: false }));
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {showModal?.name} {showModal?.type} description history
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showModal?.descriptions.map((desc) => {
            return (
              <div key={desc.updatedDate}>
                <h5>{new Date(desc.updatedDate).toLocaleString()}</h5>
                <div>{desc.description}</div>
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setShowModal((prev) => ({ ...prev, show: false }));
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <AsyncModal
        show={showDeleteAccount}
        onClose={() => setShowDeleteAccount(undefined)}
        onSave={handleOnDeleteAccount}
        title="Are you sure?"
        labelConfirm="Delete"
      >
        <>
          You are about to delete the <strong>{showDeleteAccount?.type}</strong>{" "}
          account{" "}
        </>
        <strong>{showDeleteAccount?.name}</strong>.
      </AsyncModal>
    </>
  );
};

export default AccountsTable;
