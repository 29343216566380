import { FC, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import useKeywordApi from "src/api/useKeywordApi";
import { GenericResponse } from "src/interfaces/api";
import { Keyword } from "src/interfaces/store";
import AsyncModal from "../AsyncModal";

interface Props {
  show: boolean;
  onClose: () => void;
  onSave: (keywords: Keyword[]) => Promise<GenericResponse>;
  additionalChecker?: (keyword: string) => boolean;
}

const ModalAddKeyword: FC<Props> = ({
  show,
  onClose,
  onSave,
  additionalChecker,
}) => {
  const addKeyRef = useRef<HTMLFormElement>(null);
  const [keywords, setKeywords] = useState<Keyword[]>([]);
  const { listKeywords } = useKeywordApi();
  const [typedKey, setTypedKey] = useState("");

  useEffect(() => {
    const controller = new AbortController();
    if (show) {
      (async () => {
        const resp = await listKeywords(
          { page: 1, ignorePagination: true },
          controller.signal
        );
        setKeywords(resp.list);
      })();
    }
    return () => controller.abort();
  }, [listKeywords, setKeywords, show]);

  const handleOnSaveNewKeyword = async () => {
    if (!addKeyRef.current) {
      throw new Error("Empty text filed.");
    }
    const keyword = addKeyRef.current["formKeyword"].value;
    if (
      keywords.filter((k) => !k.additional).find((k) => k.word === keyword) ||
      (additionalChecker && !additionalChecker(keyword))
    ) {
      throw new Error(
        "This keyword already exists or additional checker failed."
      );
    }
    const isolated = addKeyRef.current["isolated"].checked;
    const matchInDescription = addKeyRef.current["matchInDescription"].checked;
    const resp = await onSave([
      {
        word: keyword,
        isolated,
        matchInDescription,
        additional: 0,
      },
    ]);
    onClose();
    return resp;
  };

  const existingKey = keywords
    .filter((k) => !k.additional)
    .find((k) => k.word === typedKey);

  return (
    <AsyncModal
      show={show}
      onClose={onClose}
      onSave={() => handleOnSaveNewKeyword()}
      title="Add keyword"
      labelConfirm="Save"
    >
      <Form ref={addKeyRef}>
        <Form.Group controlId="formKeyword">
          <Form.Label>Keyword</Form.Label>
          <Form.Control
            value={typedKey}
            type="text"
            placeholder="web3"
            onChange={(ev) => setTypedKey(ev.target.value)}
            style={{
              ...(existingKey
                ? {
                    color: "var(--bs-danger)",
                  }
                : {}),
            }}
          />
          <Form.Text
            style={{
              height: "20px",
              display: "inline-block",
              overflow: "hidden",
            }}
          >
            {existingKey && `there's already a keyword ${typedKey}.`}
          </Form.Text>
          <Form.Check type="checkbox" id="isolated" label="is isolated" />
          <Form.Check
            type="checkbox"
            id="matchInDescription"
            label="match in description"
          />
        </Form.Group>
      </Form>
    </AsyncModal>
  );
};

export default ModalAddKeyword;
