import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, NavLink, useLocation } from "react-router-dom";
import useGoogleLogout from "../../hooks/useGoogleLogout";
import useTheme from "../../hooks/useTheme";
import { SessionStore } from "../../store";
import LoginButton from "../LoginButton";
import ThemeButton from "../ThemeButton";
import st from "./header.module.scss";

const Header = () => {
  const [theme] = useTheme();
  const credential = SessionStore.useState<string>((s) => s.id_token);
  const logout = useGoogleLogout();

  const location = useLocation();

  const isScraper = location.pathname.startsWith("/scraper");

  return (
    <Navbar bg={theme} variant={theme} expand="lg">
      <Container>
        <span className={st.titleContainer}>
          <ThemeButton className={st.toggleTheme} />
          <Navbar.Brand as={Link} to="/">
            Lightshift
          </Navbar.Brand>
        </span>
        {credential ? (
          <>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav>
                {isScraper && (
                  <>
                    <Nav.Link as={NavLink} end to="/scraper">
                      Matches
                    </Nav.Link>
                    <Nav.Link as={NavLink} to="/scraper/accounts-bios">
                      Bio Bot
                    </Nav.Link>
                    <Nav.Link as={NavLink} to="/scraper/grants">
                      Grants Bot
                    </Nav.Link>
                    <Nav.Link as={NavLink} to="/scraper/leaderboard">
                      Leaderboard
                    </Nav.Link>
                    <Nav.Link as={NavLink} to="/scraper/dashboard">
                      Dashboard
                    </Nav.Link>
                  </>
                )}
                <button className={st.logoutButton} onClick={() => logout()}>
                  Logout
                </button>
              </Nav>
            </Navbar.Collapse>
          </>
        ) : (
          <LoginButton />
        )}
      </Container>
    </Navbar>
  );
};

export default Header;
