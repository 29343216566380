import { useCallback } from "react";
import { GenericResponse, IListMatchRequest } from "src/interfaces/api";
import { Match, MatchList } from "../interfaces/store";
import useApi from "./useApi";

interface IUpdateMatchRequest {
  body: {
    values?: Record<string, string | number | boolean | number[]>;
    matchIds: number[];
  };
}
interface IGetMatchByIdRequest {
  matchId: string;
}

type IUpdateMatch = (
  params: IUpdateMatchRequest,
  signal?: AbortSignal
) => Promise<GenericResponse>;
type IGetMatchById = (
  params: IGetMatchByIdRequest,
  signal?: AbortSignal
) => Promise<Match>;
type IListMatches = (
  params: IListMatchRequest,
  signal?: AbortSignal
) => Promise<MatchList>;

export default function useMatchApi(): {
  updateMatch: IUpdateMatch;
  getMatchById: IGetMatchById;
  listMatches: IListMatches;
} {
  const request = useApi();

  const updateMatch: IUpdateMatch = useCallback(
    ({ body }, signal) =>
      request({
        endpoint: `/matches`,
        method: "PATCH",
        body: JSON.stringify(body),
        signal,
      }),
    [request]
  );

  const getMatchById: IGetMatchById = useCallback(
    ({ matchId }, signal) =>
      request({
        endpoint: `/matches/${matchId}`,
        method: "GET",
        signal,
      }),
    [request]
  );

  const listMatches: IListMatches = useCallback(
    (
      { page, keywords, accounts, pageSize, orderBy, orderDirection, filterBy },
      signal
    ) =>
      request({
        endpoint: "/matches",
        method: "GET",
        search: {
          page: page.toString(),
          ...(keywords ? { keywords } : {}),
          ...(accounts ? { accounts } : {}),
          ...(pageSize ? { pageSize } : {}),
          ...(orderBy ? { orderBy } : {}),
          ...(orderDirection ? { orderDirection } : {}),
          ...(filterBy ? { filterBy } : {}),
        },
        signal,
      }),
    [request]
  );
  return { getMatchById, listMatches, updateMatch };
}
