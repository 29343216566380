import { googleLogout } from "@react-oauth/google";
import { useCallback } from "react";
import { SessionStore } from "src/store";
import useUpdateSessionData from "./useUpdateSessionData";

export default function useGoogleLogout() {
  const updateSession = useUpdateSessionData(SessionStore);
  const logout = useCallback(
    (tryRefreshToken?: boolean) => {
      updateSession(
        {
          access_token: "",
          expires_at: "",
          expires_in: 0,
          id_token: "",
          scope: [],
          token_type: "",
          ...(tryRefreshToken ? {} : { refresh_token: "" }),
        },
        true
      );
      googleLogout();
    },
    [updateSession]
  );
  return logout;
}
