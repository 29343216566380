import { useEffect } from "react";
import { useQuery } from "react-query";
import { Location } from "react-router-dom";
import useGoogleAuthApi from "src/api/useGoogleAuthApi";
import { RefreshTokenResponse } from "src/interfaces/api";
import { SessionStore } from "src/store";
import useUpdateSessionData from "./useUpdateSessionData";

function parseNowTime(ms: number): string {
  const h = Math.floor(ms / (1000 * 60 * 60));
  const m = Math.floor((ms - h * 1000 * 60) / (1000 * 60));
  const s = Math.floor((ms - m * 1000 * 60) / 1000);
  return `${h}h ${m}m ${s}s`;
}

export default function useRefreshToken() {
  const { refreshToken } = useGoogleAuthApi();
  const updateSession = useUpdateSessionData(SessionStore);

  const [token, , expiresAt, refresh] = SessionStore.useState<
    [string, Location | undefined, string, string]
  >((s) => [s.id_token, s.path, s.expires_at, s.refresh_token]);

  const expiresDate = parseFloat(expiresAt || "0") * 1000;

  const results = useQuery<RefreshTokenResponse | undefined, Error>(
    ["refreshSession"],
    ({ signal }) => {
      /* Only refresh when 5 min to expire: */
      if (expiresDate - Date.now() > 1000 * 60 * 5 && token) {
        console.log(
          `%c${parseNowTime(expiresDate - Date.now())} %cto refresh the token`,
          "color: #bada55",
          "color: default"
        );
        return;
      }
      console.log("%cToken Refreshed!", "color: red");
      return refreshToken({ refreshToken: refresh }, signal);
    },
    {
      enabled: !!refresh,
      refetchInterval: 1000 * 60 * 2, // recheck every 2 min
      refetchIntervalInBackground: true,
      refetchOnReconnect: "always",
      refetchOnWindowFocus: "always",
    }
  );

  const sessionData = results.data;

  useEffect(() => {
    if (refresh && !token) {
      results.refetch();
    }
  }, [refresh, results, token]);

  useEffect(() => {
    if (!!sessionData) {
      updateSession({
        access_token: sessionData.tokens.access_token,
        expires_in: sessionData.tokens.expires_in.toString(),
        expires_at:
          sessionData.tokens.expires_at || sessionData.tokens.expires_in
            ? results.dataUpdatedAt / 1000 + sessionData.tokens.expires_in
            : 0,
        scope: sessionData.tokens.scope.join(","),
        token_type: sessionData.tokens.token_type,
      });
    }
  }, [results.dataUpdatedAt, sessionData, updateSession]);

  if (results.isError) {
    console.error(results);
  }

  useEffect(() => {
    if (results.isError) {
      updateSession({
        refresh_token: "",
      });
    }
  }, [results.isError, updateSession]);

  return results;
}
