import { FC, useEffect } from "react";
import { Container, Dropdown, Form } from "react-bootstrap";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import useGrantsApi from "src/api/useGrantsApi";
import GrantCard from "src/components/GrantCard";
import Spinner from "src/components/Spinner";
import TablePager from "src/components/TablePager";
import { GitcoinSortBy, IGitcoinSortOptions } from "src/interfaces/gitCoin";
import matchSt from "../Matches/matches.module.scss";
import st from "./grants.module.scss";
import cls from "classnames";

const Grants: FC = () => {
  const { listGrants } = useGrantsApi();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page");
  const pageSize = searchParams.get("pageSize");
  const orderBy = searchParams.get("orderBy") as IGitcoinSortOptions;

  useEffect(() => {
    if (!page || !pageSize || !orderBy) {
      searchParams.set("page", "1");
      searchParams.set("pageSize", "20");
      searchParams.set("orderBy", "weighted_shuffle");
      setSearchParams(searchParams, { replace: true });
    }
  }, [orderBy, page, pageSize, searchParams, setSearchParams]);

  const { data, isLoading, isFetching } = useQuery(
    ["grantsList", page, pageSize, orderBy],
    async ({ signal }) =>
      listGrants(
        {
          page: parseInt(page || "1", 10),
          pageSize: pageSize || "20",
          orderBy: orderBy || "weighted_shuffle",
        },
        signal
      ),
    {
      enabled: !!page && !!pageSize && !!orderBy,
      keepPreviousData: true,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    }
  );

  const categories: Map<string, [{ title: string; key: string }]> = new Map();

  Object.entries(GitcoinSortBy).forEach((entry) => {
    if (!categories.has(entry[1].category)) {
      categories.set(entry[1].category, [
        { key: entry[0], title: entry[1].title },
      ]);
    } else {
      categories
        .get(entry[1].category)
        ?.push({ key: entry[0], title: entry[1].title });
    }
  });

  const pager = (showActions?: boolean) => (
    <TablePager
      total={data?.count || 0}
      page={parseInt(page || "1", 10)}
      pageSize={parseInt(pageSize || "20", 10)}
      onPageChange={(newPage) => {
        searchParams.set("page", newPage.toString());
        setSearchParams(searchParams);
      }}
      onPageSizeChange={(newPageSize) => {
        searchParams.set("page", "1");
        searchParams.set("pageSize", newPageSize.toString());
        setSearchParams(searchParams);
      }}
      actionButtons={
        showActions && orderBy && page && pageSize ? (
          <Form.Group>
            <Dropdown
              onSelect={(selected: string | null) => {
                searchParams.set("page", "1");
                searchParams.set("orderBy", selected || "weighted_shuffle");
                setSearchParams(searchParams);
              }}
            >
              <Dropdown.Toggle variant="primary" id="dropdown-bulk">
                {GitcoinSortBy[orderBy].title}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {Array.from(categories).map(([cat, entries]) => (
                  <>
                    <Dropdown.ItemText
                      key={cat}
                      className={st.dropdownCategory}
                    >
                      {cat}
                    </Dropdown.ItemText>
                    {entries.map(({ key, title }) => (
                      <Dropdown.Item key={key} eventKey={key}>
                        {title}
                      </Dropdown.Item>
                    ))}
                  </>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        ) : (
          ""
        )
      }
    />
  );

  return (
    <Container>
      <h1>Grants</h1>
      {pager(true)}
      <div className={matchSt.tableContainer}>
        <Spinner className={matchSt.spinner} show={isLoading || isFetching} />
        <div className={cls(st.cardsList, { [st.loading]: isFetching })}>
          {data?.grants.map((grant) => (
            <GrantCard key={grant.id} grant={grant} />
          ))}
        </div>
      </div>
      {pager()}
    </Container>
  );
};

export default Grants;
