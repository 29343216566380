import { registerInDevtools, Store } from "pullstate";
import {
  BiosAccountsList,
  KeywordList,
  LeaderList,
  MatchList,
  PoiList,
  Token,
} from "../interfaces/store";

export const SessionStore = new Store<Token>({
  access_token: localStorage.getItem("access_token") || "",
  expires_at: localStorage.getItem("expires_at") || "",
  expires_in: parseInt(localStorage.getItem("expires_in") || "0", 10),
  id_token: localStorage.getItem("id_token") || "",
  refresh_token: localStorage.getItem("refresh_token") || "",
  scope: (localStorage.getItem("scope") || "").split(","),
  token_type: localStorage.getItem("token_type") || "",
});

export const PoiStore = new Store<PoiList>({
  list: [],
  page: 0,
  pageSize: 20,
  totalAmount: 0,
  loading: false,
  error: null,
  filterBy: null,
  types: [],
  orderBy: "poiId",
  orderDirection: "DESC",
});

export const KeywordStore = new Store<KeywordList>({
  list: [],
  types: [],
  page: 0,
  pageSize: 20,
  totalAmount: 0,
  loading: false,
  error: null,
  orderBy: "word",
  orderDirection: "DESC",
});

export const MatchStore = new Store<MatchList>({
  list: [],
  page: 0,
  pageSize: 20,
  totalAmount: 0,
  loading: false,
  error: null,
  filterBy: null,
  orderBy: "matchId",
  orderDirection: "DESC",
});

export const LeaderStore = new Store<LeaderList>({
  list: [],
  page: 0,
  pageSize: 20,
  totalAmount: 0,
  loading: false,
  error: null,
  orderBy: "poiId",
  orderDirection: "DESC",
  interval: 1,
  intervalType: "WEEK",
});

export const AccountsBiosStore = new Store<BiosAccountsList>({
  list: [],
  loading: false,
  error: null,
});

// Register as many or as few Stores as you would like to monitor in the devtools
registerInDevtools({
  SessionStore,
  PoiStore,
  KeywordStore,
  MatchStore,
  LeaderStore,
});
