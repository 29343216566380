import { FC, useEffect, useState } from "react";
import { Badge, Button, Form } from "react-bootstrap";
import useKeywordApi from "src/api/useKeywordApi";
import { GenericResponse } from "src/interfaces/api";
import { GenericKeyword, Keyword } from "src/interfaces/store";
import AsyncModal from "../AsyncModal";
import st from "./modal-blacklist-keyword.module.scss";

interface Props {
  show: boolean;
  onClose: () => void;
  onSave: (keys: Keyword[], isBlacklist: boolean) => Promise<GenericResponse>;
  defaultBlacklisted?: GenericKeyword[];
}

const ModalBlacklistKeyword: FC<Props> = ({
  show,
  onClose,
  onSave,
  defaultBlacklisted,
}) => {
  const [blacklisted, setBlacklisted] = useState<Keyword[]>([]);
  const [search, setSearch] = useState<string>("");
  const [keywords, setKeywords] = useState<Keyword[]>([]);
  const { listKeywords } = useKeywordApi();

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      const resp = await listKeywords(
        { page: 1, ignorePagination: true },
        controller.signal
      );
      setKeywords(
        resp.list.filter(
          (k) => !(defaultBlacklisted || []).map((e) => e.word).includes(k.word)
        )
      );
      return () => controller.abort();
    })();
  }, [defaultBlacklisted, listKeywords, setKeywords]);

  return (
    <AsyncModal
      show={show}
      onClose={onClose}
      onSave={async () => {
        const resp = await onSave(blacklisted, true);
        setBlacklisted([]);
        return resp;
      }}
      title="Blacklist keyword"
      labelConfirm="Save"
    >
      <div className={st.badges}>
        {blacklisted.map((key) => (
          <Badge key={key.word}>
            {key.word}
            <button
              className={st.filterBadge}
              onClick={() =>
                setBlacklisted((prev) =>
                  prev.filter((k) => k.word !== key.word)
                )
              }
            >
              <i className="bi bi-x"></i>
            </button>
          </Badge>
        ))}
      </div>
      <Form.Control
        type="text"
        placeholder="keyword"
        value={search}
        onChange={(ev) => setSearch(ev.target.value)}
      />
      <div className={st.listButton}>
        {keywords
          .filter((k) => k.word.includes(search))
          .map((key) => (
            <Button
              key={key.word + "_btn"}
              onClick={() =>
                setBlacklisted((prev) =>
                  prev.find((k) => k.word === key.word)
                    ? prev.filter((k) => k.word !== key.word)
                    : [...prev, key]
                )
              }
              variant={
                blacklisted.find((el) => el.word === key.word)
                  ? "primary"
                  : "light"
              }
            >
              {key.word}
            </Button>
          ))}
      </div>
    </AsyncModal>
  );
};

export default ModalBlacklistKeyword;
