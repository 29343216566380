import cls from "classnames";
import { FC, useEffect, useRef, useState } from "react";
import { Badge, Button, Dropdown, Form, Table } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import usePoiApi from "src/api/usePoiApi";
import AsyncModal from "src/components/Modal/AsyncModal";
import ModalAddPoi from "src/components/Modal/ModalAddPoi";
import Spinner from "src/components/Spinner";
import TableOrderButton from "src/components/TableOrderButton";
import TablePager from "src/components/TablePager";
import usePoisStore from "src/hooks/endpoint/usePoisStore";
import { Poi, TableOrderDirection } from "src/interfaces/store";
import { PoiStore } from "src/store";
import AccountsFilterInput from "../Matches/AccountsFilterInput";
import { ReactComponent as DataNotFoundImage } from "../Matches/data-not-found.svg";
import KeywordsFilterInput from "../Matches/KeywordsFilterInput";
import matchSt from "../Matches/matches.module.scss";

const PoisListTab: FC<{ className?: string }> = ({ className }) => {
  const {
    setPage,
    setPageSize,
    setOrderBy,
    updateFilterBy,
    setKeywords,
    setAccounts,
  } = usePoisStore();
  const { addNewPoi, deletePoi, deletePoisList } = usePoiApi();
  const [searchParams] = useSearchParams();
  const loading = PoiStore.useState((s) => s.loading);
  const list = PoiStore.useState((s) => s.list);
  const types = PoiStore.useState((s) => s.types);
  const page = PoiStore.useState((s) => s.page);
  const total = PoiStore.useState((s) => s.totalAmount);
  const orderBy = PoiStore.useState((s) => s.orderBy);
  const orderDirection = PoiStore.useState((s) => s.orderDirection);
  const pageSize = searchParams.get("pageSize");
  const keywords = searchParams.get("keywords");
  const accounts = searchParams.get("accounts");
  const filterBy = JSON.parse(searchParams.get("filterBy") || "{}");
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState(filterBy["name"] || "");
  const [showAddPoi, setShowAddPoi] = useState(false);
  const [showDeletePoi, setShowDeletePoi] = useState<Poi>();
  const [showBulkDeletePois, setShowBulkDeletePois] = useState<boolean>();
  const [bulkSelected, setBulkSelected] = useState<number[]>([]);
  const shiftKey = useRef(false);
  const removeBadgeKeywordFn = useRef((word: string) => {});
  const removeBadgeAccountFn = useRef((accountId: string) => {});
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      updateFilterBy("name", search);
    }, 500);
    return () => clearTimeout(timeout);
  }, [search, updateFilterBy]);

  const handleOnUpdateOrder = (dir: TableOrderDirection, key: keyof Poi) => {
    setOrderBy(dir, key);
  };

  const handleOnRowClick = (poiId: number) => {
    navigate(`/scraper/dashboard/pois/${poiId}`);
  };

  const handleOnAddNewPoi = async (name: string, description: string) => {
    const resp = await addNewPoi({ name, description });
    if (resp.status === "success") {
      PoiStore.update((s) => {
        s.list.unshift(resp.poi);
        if (!types.includes(description)) {
          s.types.push(description);
        }
      });
    }
    return resp;
  };

  const handleOnDeletePoi = async (poi: Poi) => {
    const resp = await deletePoi({ poiId: poi.poiId.toString() });
    if (resp.status === "success") {
      PoiStore.update((s) => {
        s.list = s.list.filter((p) => p.poiId !== poi.poiId);
      });
    }
    return resp;
  };

  const handleOnDeletePoisList = async () => {
    const resp = await deletePoisList({ poisIds: bulkSelected });
    if (resp.status === "success") {
      PoiStore.update((s) => {
        s.list = s.list.filter((p) =>
          bulkSelected.every((poiId) => p.poiId !== poiId)
        );
      });
    }
    return resp;
  };

  const handleOnSelectAll = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (ev.target.checked) {
      setBulkSelected(list.map((poi) => poi.poiId));
    } else {
      setBulkSelected([]);
    }
  };

  const handleOnSelectedRow = (
    ev: React.ChangeEvent<HTMLInputElement>,
    poiId: number
  ) => {
    if (ev.target.checked) {
      if (shiftKey.current) {
        setBulkSelected((prev) => {
          const allEls = list.map((el) => el.poiId);
          const firstIndex = allEls.indexOf(prev.slice(-1)[0]);
          const secIndex = allEls.indexOf(poiId);
          const finalArr = allEls.slice(
            firstIndex < secIndex ? firstIndex : secIndex,
            firstIndex > secIndex ? firstIndex : secIndex + 1
          );
          return prev.concat(
            finalArr.filter((item) => prev.indexOf(item) === -1)
          );
        });
      } else {
        setBulkSelected((prev) => [...prev, poiId]);
      }
    } else {
      setBulkSelected((prev) => prev.filter((el) => el !== poiId));
    }
  };

  const bulkActionSelected = (ev: string | null) => {
    if (ev === "delete") {
      setShowBulkDeletePois(true);
    }
  };

  const selectedType = filterBy["description"];

  const pager = (showActions?: boolean) => (
    <TablePager
      total={total as number}
      page={page as number}
      pageSize={parseInt(pageSize || "20", 10)}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
      actionButtons={
        showActions ? (
          <>
            <Button onClick={() => setShowFilter((prev) => !prev)}>
              {showFilter ? (
                <i className="bi bi-funnel"></i>
              ) : (
                <i className="bi bi-funnel-fill"></i>
              )}
            </Button>
            <Form.Control
              value={search}
              onChange={(ev) => setSearch(ev.target.value)}
              placeholder="search"
            />
            <Button onClick={() => setShowAddPoi((prev) => !prev)}>
              <i className={"bi bi-plus-lg"}></i>
            </Button>
            <Form.Group>
              <Dropdown onSelect={bulkActionSelected}>
                <Dropdown.Toggle
                  disabled={bulkSelected.length === 0}
                  variant="primary"
                  id="dropdown-bulk"
                >
                  Bulk Actions
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="delete">Delete</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>
          </>
        ) : undefined
      }
    />
  );

  return (
    <div className={className}>
      <div className={matchSt.filterResults}>
        {keywords
          ?.split(",")
          .filter((e) => e !== "")
          .sort()
          .map((key) => (
            <Badge key={key}>
              {key}{" "}
              <button
                className={matchSt.filterBadge}
                onClick={() => removeBadgeKeywordFn.current(key)}
              >
                <i className="bi bi-x"></i>
              </button>
            </Badge>
          ))}
        {accounts
          ?.split(",")
          .filter((e) => e !== "")
          .sort()
          .map((acc) => (
            <Badge bg="info" key={acc}>
              {acc}{" "}
              <button
                className={matchSt.filterBadge}
                onClick={() => removeBadgeAccountFn.current(acc)}
              >
                <i className="bi bi-x"></i>
              </button>
            </Badge>
          ))}
      </div>
      <div
        className={cls(matchSt.filtersContainer, {
          [matchSt.showFilters]: showFilter,
        })}
      >
        <div className={matchSt.filterContent}>
          <div className={matchSt.filterInputs}>
            <KeywordsFilterInput
              onChange={setKeywords}
              removeWordFunc={removeBadgeKeywordFn}
              update={showFilter}
            />
            <Form>
              <Form.Label>Type</Form.Label>
              <Dropdown
                onSelect={(ev) => updateFilterBy("description", ev || "")}
              >
                <Dropdown.Toggle>
                  {filterBy["description"] || "Select one type"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {!!filterBy["description"] && (
                    <Dropdown.Item eventKey="">None</Dropdown.Item>
                  )}
                  {types
                    .filter((t) => t !== selectedType)
                    .map((type) => (
                      <Dropdown.Item eventKey={type} key={type}>
                        {type}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            </Form>
            <AccountsFilterInput
              onChange={setAccounts}
              removeAccountFunc={removeBadgeAccountFn}
              update={showFilter}
            />
          </div>
        </div>
      </div>
      {pager(true)}
      <div className={matchSt.tableContainer}>
        <Spinner className={matchSt.spinner} show={loading} />
        <Table className={cls(matchSt.table, { [matchSt.loading]: loading })}>
          <thead>
            <tr>
              <th>
                <TableOrderButton
                  onClick={handleOnUpdateOrder}
                  orderDirection={orderDirection}
                  orderKey="poiId"
                  order={orderBy}
                  onCheck={handleOnSelectAll}
                  checked={(list || []).length === bulkSelected.length}
                >
                  ID
                </TableOrderButton>
              </th>
              <th>
                <TableOrderButton
                  onClick={handleOnUpdateOrder}
                  orderDirection={orderDirection}
                  orderKey="name"
                  order={orderBy}
                >
                  Name
                </TableOrderButton>
              </th>
              <th>
                <TableOrderButton
                  onClick={handleOnUpdateOrder}
                  orderDirection={orderDirection}
                  orderKey="description"
                  order={orderBy}
                >
                  Type
                </TableOrderButton>
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {!loading && page > 0 && (!list || list.length === 0) ? (
              <tr>
                <td className={matchSt.emptyState} colSpan={4}>
                  <DataNotFoundImage className={matchSt.noDataImage} />
                  <br />
                  There is no data to display.
                </td>
              </tr>
            ) : (
              !!list &&
              list.map((poi) => {
                return (
                  <tr
                    onClick={() => handleOnRowClick(poi.poiId)}
                    className={matchSt.rowEntry}
                    key={poi.poiId}
                  >
                    <td>
                      <div className={matchSt.idTable}>
                        <input
                          checked={bulkSelected.some((el) => el === poi.poiId)}
                          onClick={(ev) => {
                            ev.stopPropagation();
                            shiftKey.current = ev.shiftKey;
                          }}
                          type="checkbox"
                          onChange={(ev) => handleOnSelectedRow(ev, poi.poiId)}
                        />
                        {poi.poiId}
                      </div>
                    </td>
                    <td>{poi.name}</td>
                    <td>{poi.description}</td>
                    <td>
                      <button
                        title="Delete POI"
                        className={matchSt.noBtn}
                        onClick={(ev) => {
                          ev.stopPropagation();
                          setShowDeletePoi(poi);
                        }}
                      >
                        <i
                          className={cls(
                            "bi bi-trash-fill",
                            matchSt.icon,
                            matchSt.danger
                          )}
                        ></i>
                      </button>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
      </div>
      {pager()}
      <AsyncModal
        show={showBulkDeletePois}
        onSave={handleOnDeletePoisList}
        onClose={() => setShowBulkDeletePois(false)}
        title="Are you sure?"
        labelConfirm="Delete"
      >
        You are about to delete:
        <ul>
          {list
            .filter((el) => bulkSelected.find((b) => el.poiId === b))
            .map((selected) => (
              <li key={selected.poiId}>{selected.name}</li>
            ))}
        </ul>
      </AsyncModal>
      <AsyncModal
        show={showDeletePoi}
        onSave={handleOnDeletePoi}
        onClose={() => setShowDeletePoi(undefined)}
        title="Are you sure?"
        labelConfirm="Delete"
      >
        You are about to delete "{showDeletePoi?.name}"
      </AsyncModal>
      <ModalAddPoi
        show={showAddPoi}
        onClose={() => setShowAddPoi(false)}
        types={types}
        onSave={handleOnAddNewPoi}
      />
    </div>
  );
};

export default PoisListTab;
