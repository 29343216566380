import { Location } from "react-router-dom";

export const BotTypeValues = ["twitter", "linkedin"] as const;

export type BotType = typeof BotTypeValues[number];

export type IntervalType = "WEEK" | "MONTH" | "YEAR";

export type MatchScore =
  | "unscored"
  | "TFEB"
  | "blacklisted"
  | "interested"
  | "invested";

export const MatchScoresProps: Record<
  MatchScore,
  { title: string; className: string }
> = {
  unscored: { title: "Unscored", className: "secondary" },
  TFEB: { title: "TFEB", className: "primary" },
  blacklisted: { title: "Blacklisted", className: "danger" },
  interested: { title: "Interested", className: "info" },
  invested: { title: "Invested", className: "success" },
} as const;

export type TableOrderDirection = "ASC" | "DESC";

export interface GenericKeyword {
  word: string;
  matchInDescription: boolean;
  isolated: boolean;
  blacklisted?: number;
}

export interface GenericPagedList {
  page: number;
  pageSize: number;
  totalAmount: number;
  loading: boolean;
  error: Error | null; // todo: Implement custom errors!
}

export interface GenericList<T> extends GenericPagedList {
  orderBy: keyof T;
  orderDirection: TableOrderDirection;
}

export interface Keyword extends GenericKeyword {
  additional: number;
}

export interface AccountDescription {
  description: string;
  updatedDate: number;
}

export interface Account {
  accountId: string;
  type: BotType;
  url: string;
  name: string;
  diffChecked: number;
  toScrape: boolean;
  active: boolean;
  descriptions: AccountDescription[];
}

export interface BiosAccount {
  accountId: string;
  type: BotType;
  url: string;
  name: string;
  diffChecked: number;
  descriptions: AccountDescription[];
}

export interface Poi {
  poiId: number;
  name: string;
  description: string;
  keywords: GenericKeyword[];
  blacklistedKeywords: GenericKeyword[];
  accounts: Account[];
}

export interface Match {
  matchId: number;
  initialMatchDate: number;
  lastMatchDate: number;
  read: boolean;
  score: MatchScore;
  url: string;
  keywords: Keyword[];
  accounts: Account[];
  botType?: BotType;
  name?: string;
  position?: string;
  company?: string;
}

export interface LeaderEntry {
  poiId: number;
  name: string;
  description: string;
  unscored: number;
  blacklisted: number;
  interested: number;
  invested: number;
  TFEB: number;
  total: number;
  ratio: number;
}

export interface AccountList extends GenericList<Account> {
  list: Account[];
}

export interface BiosAccountsList {
  list: BiosAccount[];
  loading: boolean;
  error: Error | null; // todo: Implement custom errors!
}

export interface KeywordList extends GenericList<Keyword> {
  list: Keyword[];
  types: string[];
}

export interface PoiList extends GenericList<Poi> {
  list: Poi[];
  filterBy: Record<string, string> | null;
  types: string[];
}

export interface MatchList extends GenericList<Match> {
  list: Match[];
  filterBy: Record<string, string> | null;
}

export interface LeaderList extends GenericList<LeaderEntry> {
  list: LeaderEntry[];
  interval: number;
  intervalType: IntervalType;
}

export interface Token {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  scope: string[];
  token_type: string;
  id_token: string;
  expires_at: string;
  path?: Location;
}

export interface PortalStats {
  id: number;
  txCount: number;
  lastUpdate: number;
  totalFeesUSD: number;
  totalVolumeUSD: number;
  dailyTxCount: number;
}

export interface PortalsStatsList {
  list: PortalStats[];
}
