import { FC, useState } from "react";
import { Button } from "react-bootstrap";
import { GenericResponse } from "src/interfaces/api";
import { Match, MatchScore, MatchScoresProps } from "src/interfaces/store";
import ModalChangeScore from "../Modal/ModalChangeScore";
import st from "./score-button.module.scss";

interface Props {
  match?: Match;
  changeScore: (match: Match, value: MatchScore) => Promise<GenericResponse>;
  noDataLabel?: string;
}

const ScoreButton: FC<Props> = ({ match, changeScore, noDataLabel }) => {
  const [show, setShow] = useState(false);
  const [value, setValue] = useState<MatchScore>(match?.score || "unscored");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleOnScoreButtonClick = (
    ev: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    ev.stopPropagation();
    handleShow();
  };

  const handleOnSave = () => changeScore(match as Match, value);

  return (
    <>
      <Button
        variant={
          !!match?.score ? MatchScoresProps[match.score].className : "secondary"
        }
        className={st.scoreButton}
        onClick={handleOnScoreButtonClick}
        disabled={!match}
      >
        {!!match
          ? match.score.charAt(0).toUpperCase() + match.score.slice(1)
          : noDataLabel || " - "}
      </Button>
      <ModalChangeScore
        title={`Change score to match ${match?.matchId}`}
        onHide={handleClose}
        show={show}
        onChange={(e) => setValue(e)}
        onSave={handleOnSave}
        value={value}
      />
    </>
  );
};

export default ScoreButton;
