import { FC, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { AddNewPoiResponse } from "src/api/usePoiApi";
import AsyncModal from "../AsyncModal";

interface Props {
  show: boolean;
  types: string[];
  onClose: () => void;
  onSave: (newName: string, newType: string) => Promise<AddNewPoiResponse>;
}

const ModalAddPoi: FC<Props> = ({ show, onClose, types, onSave }) => {
  const [newName, setNewName] = useState("");
  const [newType, setNewType] = useState("");
  const [newCustomType, setNewCustomType] = useState("");

  const handleOnClickSave = () => {
    if (!newName || (!newType && !newCustomType)) {
      throw new Error("All the fields must be filled");
    }
    return onSave(newName, newType !== "custom" ? newType : newCustomType);
  };

  return (
    <AsyncModal
      show={show}
      onClose={onClose}
      onSave={handleOnClickSave}
      title="Add new POI"
      labelConfirm="Save"
    >
      <Form>
        <Form.Group style={{ marginBottom: "8px" }} controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Vitalik"
            value={newName}
            onChange={(ev) => setNewName(ev.target.value)}
          />
        </Form.Group>
        <Form.Label>Type</Form.Label>
        <Form.Group
          style={{ display: "flex", gap: "8px" }}
          controlId="formType"
        >
          <Dropdown onSelect={(ev) => setNewType(ev || "")}>
            <Dropdown.Toggle>{newType || "Select one type"}</Dropdown.Toggle>
            <Dropdown.Menu>
              {types.map((type) => (
                <Dropdown.Item eventKey={type} key={type}>
                  {type}
                </Dropdown.Item>
              ))}
              <Dropdown.Item eventKey="custom">Add custom</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {newType === "custom" && (
            <Form.Control
              type="text"
              placeholder="KPOI"
              value={newCustomType}
              onChange={(ev) => setNewCustomType(ev.target.value)}
            />
          )}
        </Form.Group>
      </Form>
    </AsyncModal>
  );
};

export default ModalAddPoi;
