import { useEffect, useState } from "react";
import usePoiApi from "src/api/usePoiApi";
import { AccountsBiosStore } from "src/store";

export default function useBiosAccountsStore(): {
  refresh: () => void;
  setLoading: (loading: boolean) => void;
} {
  const [forceRefresh, setForceRefresh] = useState(1);
  const { listAllBiosAccounts } = usePoiApi();

  const refresh = () => {
    setForceRefresh((prev) => prev * -1);
  };

  const setLoading = () => {
    AccountsBiosStore.update((s) => {
      s.loading = true;
    });
  };

  useEffect(() => {
    AccountsBiosStore.update((s) => {
      s.loading = true;
    });
    const controller = new AbortController();
    (async () => {
      try {
        const result = await listAllBiosAccounts(controller.signal);
        AccountsBiosStore.update((s) => {
          s.list = result.list;
          s.loading = false;
        });
      } catch (err) {
        if ((err as DOMException).name !== "AbortError") {
          AccountsBiosStore.update((s) => {
            s.error = err as Error;
            s.loading = false;
          });
        }
      }
    })();
    return () => controller.abort();
  }, [listAllBiosAccounts, forceRefresh]);

  return { refresh, setLoading };
}
