export type IGitcoinSortOptions =
  | "weighted_shuffle"
  | "-metadata__upcoming"
  | "-metadata__gem"
  | "-metadata__cv"
  | "-last_update"
  | "-created_on"
  | "created_on"
  | "title"
  | "-title"
  | "-clr_prediction_curve__0__1"
  | "-amount_received_in_round"
  | "-positive_round_contributor_count"
  | "-amount_received"
  | "-contributor_count";

export type IGitcoinSearchCategory = "Discover" | "Current Round" | "All-Time";

export const GitcoinSortBy: Record<
  IGitcoinSortOptions,
  { category: IGitcoinSearchCategory; title: string }
> = {
  weighted_shuffle: { category: "Discover", title: "Weighted Shuffle" },
  "-metadata__upcoming": { category: "Discover", title: "Trending" },
  "-metadata__gem": { category: "Discover", title: "Undiscovered Gems" },
  "-metadata__cv": { category: "Discover", title: "GTC Conviction Voting" },
  "-last_update": { category: "Discover", title: "Recently Updated" },
  "-created_on": { category: "Discover", title: "Newest" },
  created_on: { category: "Discover", title: "Oldest" },
  title: { category: "Discover", title: "A to Z" },
  "-title": { category: "Discover", title: "Z to A" },
  "-clr_prediction_curve__0__1": {
    category: "Current Round",
    title: "Highest Match Amount",
  },
  "-amount_received_in_round": {
    category: "Current Round",
    title: "Highest Amount Raised",
  },
  "-positive_round_contributor_count": {
    category: "Current Round",
    title: "Highest Contributor Count",
  },
  "-amount_received": {
    category: "All-Time",
    title: "Highest Amount Raised",
  },
  "-contributor_count": {
    category: "All-Time",
    title: "Highest Contributor Count",
  },
};

export interface GrantsResponse {
  applied_filters: AppliedFilters;
  grant_types: GrantType[];
  grants: Grant[];
  collections: any[];
  credentials: Credentials;
  contributions: Contributions;
  has_next: boolean;
  next_page_number: number;
  has_previous: boolean;
  count: number;
  num_pages: number;
  metadata: Metadata2;
}

interface AppliedFilters {
  grant_types?: any;
  grant_tags: string;
  grant_regions: string;
  round_num: string;
  sub_round_slug: string;
  customer_name: string;
  collection_id: string;
}

interface GrantType {
  count: number;
  label: string;
  funding: number;
  keyword: string;
  is_active: boolean;
  funding_ui: string;
  is_visible: boolean;
}

interface AdminProfile {
  url: string;
  handle: string;
  avatar_url: string;
}

interface Fields {
  handle: string;
}

interface TeamMember {
  model: string;
  pk: number;
  fields: Fields;
}

interface Metadata {
  cv: number;
  gem: number;
  related: number[][];
  upcoming: number;
  wall_of_love: any[];
  last_calc_time_related: number;
  last_calc_time_contributor_counts: number;
  last_calc_time_sybil_and_contrib_amounts: number;
}

interface Fields2 {
  name: string;
  label: string;
}

interface GrantType2 {
  model: string;
  pk: number;
  fields: Fields2;
}

interface Region {
  name: string;
  label: string;
}

export interface Grant {
  id: number;
  active: boolean;
  logo_url: string;
  details_url: string;
  title: string;
  description: string;
  description_rich: string;
  last_update: Date;
  last_update_natural: string;
  is_clr_active: boolean;
  clr_round_num: string;
  is_contract_address: boolean;
  admin_profile: AdminProfile;
  favorite: boolean;
  team_members: TeamMember[];
  is_on_team: boolean;
  clr_prediction_curve: number[][];
  last_clr_calc_date?: any;
  safe_next_clr_calc_date?: any;
  amount_received_in_round: string;
  amount_received: string;
  positive_round_contributor_count: number;
  monthly_amount_subscribed: string;
  is_clr_eligible: boolean;
  slug: string;
  url: string;
  contract_version: string;
  contract_address: string;
  token_symbol: string;
  admin_address: string;
  zcash_payout_address: string;
  celo_payout_address: string;
  zil_payout_address: string;
  polkadot_payout_address: string;
  kusama_payout_address: string;
  harmony_payout_address: string;
  binance_payout_address: string;
  rsk_payout_address: string;
  algorand_payout_address: string;
  cosmos_payout_address: string;
  token_address: string;
  image_css: string;
  verified: boolean;
  tenants: string[];
  metadata: Metadata;
  grant_type: GrantType2[];
  twitter_handle_1: string;
  twitter_handle_2: string;
  reference_url: string;
  github_project_url: string;
  funding_info?: any;
  admin_message: string;
  link_to_new_grant?: any;
  region: Region;
  has_external_funding: string;
  active_round_names: any[];
  is_idle: boolean;
  is_hidden: boolean;
}

interface Credentials {
  is_staff: boolean;
  is_authenticated: boolean;
}

interface Contributions {}

interface Metadata2 {
  claim_start_date?: any;
  claim_end_date?: any;
  start_date?: any;
  end_date?: any;
}
