import { FC, MutableRefObject, useCallback, useEffect, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import usePoiApi from "src/api/usePoiApi";
import MultiSelectSearch from "src/components/MultiSelectSearch";
import useDebounce from "src/hooks/useDebounce";
import { Account } from "src/interfaces/store";

interface Props {
  removeAccountFunc: MutableRefObject<(account: string) => void>;
  onChange: (keys: string) => void;
  update: boolean;
  style?: React.CSSProperties;
}

const AccountsFilterInput: FC<Props> = ({
  removeAccountFunc,
  onChange,
  update,
  style,
}) => {
  const [search, setSearch] = useState("");
  const { listAllAccounts } = usePoiApi();
  const [searchParams] = useSearchParams();
  const selectedAccounts = searchParams.get("accounts") || "";

  const debSearch = useDebounce(search);
  const {
    data: searchAccounts,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteQuery(
    ["MatchesAccountsList", debSearch],
    ({ pageParam = 1, signal }) =>
      listAllAccounts(
        {
          page: pageParam,
          name: debSearch,
        },
        signal
      ),
    {
      enabled: update,
      refetchOnWindowFocus: false,
      retry: false,
      getNextPageParam: (lastPage) =>
        lastPage.totalAmount > lastPage.page * lastPage.pageSize
          ? lastPage.page + 1
          : undefined,
    }
  );

  const handleOnClick = useCallback(
    (account: string) => {
      let res = selectedAccounts
        .trim()
        .split(",")
        .filter((k) => k !== "");
      if (res.includes(account)) {
        res = res.filter((f) => f !== account);
      } else {
        res.push(account);
      }
      onChange(res.join(","));
    },
    [selectedAccounts, onChange]
  );

  useEffect(() => {
    removeAccountFunc.current = handleOnClick;
  }, [handleOnClick, removeAccountFunc]);

  const handleOnChange = (query: string) => {
    setSearch(query);
  };

  return (
    <MultiSelectSearch
      label="Accounts"
      onChange={handleOnChange}
      value={search}
      loadingLabel={hasNextPage ? "Loading..." : ""}
      onLoadMore={() => {
        if (!isFetchingNextPage && !isLoading) {
          fetchNextPage();
        }
      }}
      wordsList={
        searchAccounts?.pages.reduce(
          (prev, add) => [...prev, ...add.list],
          [] as Account[]
        ) || ([] as Account[])
      }
      getLabel={(el: any) => el.name}
      getId={(el: any) => el.accountId}
      selectedWords={selectedAccounts.split(",")}
      onClick={handleOnClick}
      style={style}
    />
  );
};

export default AccountsFilterInput;
