import { useCallback } from "react";
import { GetTokenResponse, RefreshTokenResponse } from "src/interfaces/api";
import useApi from "./useApi";

interface IGetTokenRequest {
  code: string;
}

interface IRefreshTokenRequest {
  refreshToken: string;
}

type IGetToken = (
  params: IGetTokenRequest,
  signal?: AbortSignal
) => Promise<GetTokenResponse>;
type IRefreshToken = (
  params: IRefreshTokenRequest,
  signal?: AbortSignal
) => Promise<RefreshTokenResponse>;

export default function useGoogleAuthApi(): {
  getToken: IGetToken;
  refreshToken: IRefreshToken;
} {
  const request = useApi();

  const getToken: IGetToken = useCallback(
    ({ code }, signal) =>
      request({
        endpoint: "/google-auth",
        method: "POST",
        body: JSON.stringify({
          code,
          dev: process.env.NODE_ENV === "development",
        }),
        signal,
        ignoreAuthorization: true,
      }),
    [request]
  );

  const refreshToken: IRefreshToken = useCallback(
    ({ refreshToken }, signal) =>
      request({
        endpoint: "/google-auth/refresh-token",
        method: "POST",
        body: JSON.stringify({ refreshToken }),
        signal,
        ignoreAuthorization: true,
      }),
    [request]
  );

  return { getToken, refreshToken };
}
