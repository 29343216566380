import cls from "classnames";
import { FC, useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useKeywordApi from "src/api/useKeywordApi";
import usePoiApi from "src/api/usePoiApi";
import AccountsTable from "src/components/AccountsTable";
import KeywordsTable from "src/components/KeywordsTable";
import ModalAddAccount from "src/components/Modal/ModalAddAccount";
import ModalAddKeyword from "src/components/Modal/ModalAddKeyword";
import ModalBlacklistKeyword from "src/components/Modal/ModalBlacklistKeyword";
import Spinner from "src/components/Spinner";
import { GenericKeyword, Keyword, Poi as IPoi } from "src/interfaces/store";
import st from "./poi.module.scss";

const Poi: FC = () => {
  const { poiId } = useParams();
  const { getPoiById, addKeyword, deleteKeyword, addPoiNewAccount } =
    usePoiApi();
  const { updateKeyword } = useKeywordApi();
  const [poi, setPoi] = useState<IPoi>();
  const [, setError] = useState<Error>();
  const [loading, setLoading] = useState(true);
  const [showAddKeyword, setShowAddKeyword] = useState(false);
  const [showBlacklistModal, setShowBlacklistModal] = useState(false);
  const [showAddAccount, setShowAddAccount] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      setLoading(true);
      try {
        const mt = await getPoiById({ poiId: poiId || "" }, controller.signal);
        setPoi(mt);
        setLoading(false);
      } catch (err) {
        if ((err as DOMException).name !== "AbortError") {
          setLoading(false);
          console.error(err);
          setError(err as Error);
        }
      }
    })();
    return () => controller.abort();
  }, [getPoiById, poiId]);

  const handleOnRemoveClick = async (
    keyword: GenericKeyword,
    isBlacklist?: boolean
  ) => {
    if (!poiId || !poi) {
      throw new Error("No poi or poiId.");
    }
    const resp = await deleteKeyword({
      poiId,
      word: keyword.word,
      ...(isBlacklist ? { isBlacklist: true } : {}),
    });
    if (resp.status === "success") {
      setPoi((prev) =>
        prev
          ? {
              ...prev,
              [isBlacklist ? "blacklistedKeywords" : "keywords"]: prev[
                isBlacklist ? "blacklistedKeywords" : "keywords"
              ].filter((k) => k.word !== keyword.word),
            }
          : prev
      );
    }
    return resp;
  };

  const handleOnSaveNewKeyword = async (
    keywords: Keyword[],
    isBlacklisted?: boolean
  ) => {
    const word = keywords.map((k) => k.word).join(",");
    const isolated = keywords[0].isolated;
    const matchInDescription = keywords[0].matchInDescription;
    const resp = await addKeyword(poiId || "", {
      word,
      isolated,
      matchInDescription,
      ...(isBlacklisted ? { toBlacklist: true } : {}),
    });

    if (resp.status === "success") {
      setPoi((prev) =>
        prev
          ? {
              ...prev,
              [isBlacklisted ? "blacklistedKeywords" : "keywords"]: [
                ...keywords,
                ...prev[isBlacklisted ? "blacklistedKeywords" : "keywords"],
              ],
            }
          : prev
      );
    }
    return resp;
  };

  const handleOnUpdateIsolated = async (
    word: string,
    isolated: boolean,
    key: string
  ) => {
    try {
      await updateKeyword({
        word,
        body: { isolated },
      });
      setPoi((prev) => {
        if (prev) {
          return {
            ...prev,
            [key as keyof IPoi]: (
              prev[key as keyof IPoi] as GenericKeyword[]
            ).map((k) => ({
              ...k,
              isolated: k.word === word ? !k.isolated : k.isolated,
            })),
          };
        }
        return prev;
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleOnUpdateMatchInDescription = async (
    word: string,
    matchInDescription: boolean,
    key: string
  ) => {
    try {
      await updateKeyword({
        word,
        body: { matchInDescription },
      });
      setPoi((prev) => {
        if (prev) {
          return {
            ...prev,
            [key as keyof IPoi]: (
              prev[key as keyof IPoi] as GenericKeyword[]
            ).map((k) => ({
              ...k,
              matchInDescription:
                k.word === word ? !k.matchInDescription : k.matchInDescription,
            })),
          };
        }
        return prev;
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleOnSaveAddAccount = async (newAccountUrl: string) => {
    const resp = await addPoiNewAccount({
      poiId: poiId || "",
      accountUrl: newAccountUrl,
    });
    if (resp.status === "success") {
      setPoi((prev) =>
        !!prev ? { ...prev, accounts: [resp.account, ...prev.accounts] } : prev
      );
    }
    return resp;
  };

  return (
    <Container>
      <div className={cls(st.hero, { [st.isLoading]: loading })}>
        <h1>
          #{poiId} {poi?.name || (loading ? "Loading..." : "-")}
        </h1>
        <h2>{poi?.description || (loading ? "Loading..." : "-")}</h2>
      </div>
      <Spinner className={st.spinner} show={loading} />
      {!poi && !loading && (
        <div className={st.poiNotFound}>
          <h2>POI not found!</h2>
        </div>
      )}
      <div className={cls(st.poiContent, { [st.isLoading]: loading })}>
        {!!poi && (
          <>
            <div className={st.titleWithActions}>
              <h3>Accounts</h3>
              <Button
                disabled={!poi || loading}
                onClick={() => setShowAddAccount(true)}
                title="Add an account"
              >
                +
              </Button>
            </div>
            <AccountsTable
              onUpdate={(newAccount, update) => {
                setPoi((prev) =>
                  !!prev
                    ? {
                        ...prev,
                        accounts: prev.accounts.map((acc) => {
                          if (
                            acc.accountId === newAccount.accountId &&
                            acc.type === newAccount.type
                          ) {
                            return { ...acc, ...update };
                          }
                          return acc;
                        }),
                      }
                    : undefined
                );
              }}
              onDelete={(account) => {
                setPoi((prev) => {
                  if (!prev) return prev;
                  return {
                    ...prev,
                    accounts: prev.accounts.filter(
                      (acc) =>
                        !(
                          acc.type === account.type &&
                          acc.accountId === account.accountId
                        )
                    ),
                  };
                });
              }}
              accounts={poi.accounts}
              disabled={!poi || loading}
            />
            <div className={st.titleWithActions}>
              <h3>Custom Keywords</h3>
              <Button
                disabled={!poi || loading}
                onClick={() => setShowAddKeyword(true)}
                title="Add a custom keyword"
              >
                +
              </Button>
            </div>
            <KeywordsTable
              keywords={poi.keywords}
              onRemove={handleOnRemoveClick}
              onUpdateIsolated={(word: string, isolated: boolean) =>
                handleOnUpdateIsolated(word, isolated, "keywords")
              }
              onUpdateMatchInDescription={(word: string, isolated: boolean) =>
                handleOnUpdateMatchInDescription(word, isolated, "keywords")
              }
              disabled={!poi || loading}
            />
            <div className={st.titleWithActions}>
              <h3>Blacklisted Keywords</h3>
              <Button
                disabled={!poi || loading}
                onClick={() => setShowBlacklistModal(true)}
                title="Blacklist a keyword"
              >
                +
              </Button>
            </div>
            <KeywordsTable
              keywords={poi.blacklistedKeywords}
              onRemove={(w) => handleOnRemoveClick(w, true)}
              onUpdateIsolated={(word: string, isolated: boolean) =>
                handleOnUpdateIsolated(word, isolated, "blacklistedKeywords")
              }
              onUpdateMatchInDescription={(word: string, isolated: boolean) =>
                handleOnUpdateMatchInDescription(
                  word,
                  isolated,
                  "blacklistedKeywords"
                )
              }
              disabled={!poi || loading}
            />
            <ModalAddKeyword
              show={showAddKeyword}
              onClose={() => setShowAddKeyword(false)}
              onSave={handleOnSaveNewKeyword}
              additionalChecker={(key) =>
                !poi.keywords.find((k) => k.word === key)
              }
            />
            <ModalBlacklistKeyword
              show={showBlacklistModal}
              onClose={() => setShowBlacklistModal(false)}
              onSave={handleOnSaveNewKeyword}
              defaultBlacklisted={poi.blacklistedKeywords}
            />
            <ModalAddAccount
              show={showAddAccount}
              onClose={() => setShowAddAccount(false)}
              onSave={handleOnSaveAddAccount}
            />
          </>
        )}
      </div>
    </Container>
  );
};

export default Poi;
