import { useCallback } from "react";
import useGoogleLogout from "../hooks/useGoogleLogout";
import { SessionStore } from "../store";

const development = process.env.NODE_ENV === "development";
const BASE_URI = `https://ge3o2x1mm2.execute-api.eu-west-2.amazonaws.com${
  development ? "/development" : ""
}`;
const BASE_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
  env: development ? "development" : "production",
};

interface IGenericRequest {
  endpoint: string;
  method: "GET" | "POST" | "PATCH" | "DELETE" | "PUT";
  search?:
    | string
    | string[][]
    | Record<string, string>
    | URLSearchParams
    | undefined;
  body?: BodyInit;
  signal?: AbortSignal;
  ignoreAuthorization?: boolean;
}

export default function useApi() {
  const logout = useGoogleLogout();
  const credentials = SessionStore.useState((s) => s.id_token);

  const request = useCallback(
    async <T>({
      endpoint,
      method,
      search,
      body,
      signal,
      ignoreAuthorization,
    }: IGenericRequest): Promise<T> => {
      const headers = {
        ...BASE_HEADERS,
        ...(ignoreAuthorization ? {} : { authorization: credentials }),
      };
      const resp = await (
        await fetch(
          `${BASE_URI}${endpoint}${search ? "?" : ""}${new URLSearchParams(
            search
          ).toString()}`,
          {
            ...(signal ? { signal } : {}),
            method,
            headers,
            ...(body ? { body } : {}),
          }
        )
      ).json();
      if (resp.message === "Forbidden") {
        logout(true);
      }
      if (resp.status === "error") {
        throw new Error(resp.message);
      }
      return resp as T;
    },
    [credentials, logout]
  );

  return request;
}
