import { FC } from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { GenericResponse } from "src/interfaces/api";
import { MatchScore, MatchScoresProps } from "src/interfaces/store";
import AsyncModal from "../AsyncModal";
import st from "./modal-change-score.module.scss";

interface Props {
  show: boolean;
  onHide: () => void;
  onChange: (value: MatchScore) => void;
  value?: MatchScore;
  onSave: () => Promise<GenericResponse>;
  title: string;
}

const ModalChangeScore: FC<Props> = ({
  show,
  onHide,
  onChange,
  value,
  onSave,
  title,
}) => {
  return (
    <AsyncModal
      show={show}
      onClose={onHide}
      onSave={async () => {
        return await onSave();
      }}
      title={title}
      labelConfirm="Save"
      onModalClick={(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        ev.stopPropagation();
      }}
    >
      <ButtonGroup>
        <div className={st.modalBody}>
          {(
            Object.entries(MatchScoresProps) as [
              MatchScore,
              { title: string; className: string }
            ][]
          ).map(([key, { title, className }]) => (
            <ToggleButton
              key={key}
              type="radio"
              id={`t-btn-${key}`}
              value={key}
              variant={`outline-${className}`}
              checked={value === key}
              onChange={() => onChange(key)}
            >
              {title}
            </ToggleButton>
          ))}
        </div>
      </ButtonGroup>
    </AsyncModal>
  );
};

export default ModalChangeScore;
