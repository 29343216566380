import cls from "classnames";
import { FC, useLayoutEffect, useRef, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { useOnScreen } from "src/hooks/useOnScreen";
import st from "./multi-select-search.module.scss";

interface Props {
  label: string;
  onChange: (query: string) => void;
  onClick: (words: string) => void;
  wordsList: string[] | any[];
  selectedWords: string[];
  value: string;
  getLabel?: (ob: any) => string;
  getId?: (ob: any) => string;
  onLoadMore?: () => void;
  loadingLabel?: string;
  style?: React.CSSProperties;
}

const MultiSelectSearch: FC<Props> = ({
  label,
  onChange,
  wordsList,
  selectedWords,
  value,
  onClick,
  getLabel = (str: string) => str,
  getId = (str: string) => encodeURIComponent(str.trim()),
  onLoadMore,
  loadingLabel,
  style,
}) => {
  const [show, setShow] = useState(false);
  const hasMoreRef = useRef<HTMLDivElement>(null);
  const scrollableRef = useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(hasMoreRef, scrollableRef, show);

  useLayoutEffect(() => {
    if (isOnScreen && onLoadMore) {
      onLoadMore();
    }
  }, [onLoadMore, isOnScreen]);

  return (
    <>
      <div
        className={cls(st.overlay, { [st.show]: show })}
        onClick={() => setShow(false)}
      ></div>
      <Form style={style} className={st.multiSelectBox}>
        <Form.Group className={st.multiSelectGroup}>
          <Form.Label>{label}</Form.Label>
          <Form.Control
            type="text"
            onClick={() => setShow(true)}
            onChange={(ev) => {
              onChange(ev.target.value);
            }}
            value={value}
            placeholder={label}
          />
          <div className={st.multiSelectOptions}>
            <Dropdown.Menu
              show={show}
              className={st.dropdownMenu}
              ref={scrollableRef}
            >
              {wordsList.length === 0 ? (
                <Dropdown.Item>Any key math {value}</Dropdown.Item>
              ) : (
                ""
              )}
              {wordsList.map((el: any) => (
                <Dropdown.Item
                  key={getId(el)}
                  onClick={() => {
                    onClick(getId(el));
                  }}
                >
                  <span
                    className={
                      selectedWords.includes(getId(el)) ? st.selected : ""
                    }
                  >
                    {getLabel(el)}
                  </span>
                </Dropdown.Item>
              ))}
              {wordsList.length !== 0 && (
                <div ref={hasMoreRef}>{loadingLabel}</div>
              )}
            </Dropdown.Menu>
          </div>
        </Form.Group>
      </Form>
    </>
  );
};

export default MultiSelectSearch;
